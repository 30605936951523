import { swapKeyValues } from "shared/utils/swapKeyValues";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { Policy } from "shared/types/Client";
import type { ValueOf } from "shared/types/Helper";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const slfCoverageNameToLongName = {
  STD: "Short Term Disability",
  LTD: "Long Term Disability",
  VSTD: "Voluntary Short Term Disability",
  VLTD: "Voluntary Long Term Disability",
  "EE Basic Life": "Basic Life",
  "EE Basic AD&D": "Basic AD&D",
  "Vol Life": "Voluntary Life",
  "Spouse Vol Life": "Spouse Voluntary Life",
  "Vol AD&D": "Voluntary AD&D",
  "Stand-alone Vol AD&D": "Stand-alone Vol AD&D",
  Accident: "Accident",
  CI: "Critical Illness",
  HI: "Hospital Indemnity",
  GAP: "Gap",
  Dental: "Dental",
  "Dental (DHMO)": "Dental (DHMO)",
  "ASO Dental": "ASO Dental",
  Cancer: "Cancer",
  "Issue Age STD": "Issue Age STD",
  Vision: "Vision",
  "Dep Basic Life": "Dependent Basic Life",
  "Sun Advisor Advice to Pay": "SunAdvisor Advice-to-Pay",
  "SunAdvisor Advice Plus": "SunAdvisor Advice Plus",
  "Absence & ADA Solutions": "Absence & ADA Solutions",
  EAP: "Employee Assistance Program",
  DBL: "New York Disability",
  "Paid Family Leave": "New York Paid Family Leave",
  FMLA: "Family and Medical Leave Act (FMLA)",
  TDB: "New Jersey Temporary Disability",
  TDI: "Hawaii Temporary Disability",
  "Dep Basic AD&D": "Basic Dep AD&D",
  "Child Vol Life": "Child Voluntary Life",
  "Spouse Vol AD&D": "Spouse Voluntary AD&D",
  "Child Vol AD&D": "Child Voluntary AD&D",
  "Stand-alone Spouse Vol AD&D": "Standalone Spouse Vol AD&D",
  "Stand-alone Child Vol AD&D": "Standalone Child Vol AD&D",
  "Stand-alone Family Vol AD&D": "Standalone Family Vol AD&D",
  // CT PFML
  "ASO CT PFML": "ASO CT PFML",
  "CT PFML Fully Ins": "CT PFML Fully Ins",
  // MA PFML
  "ASO MA PFML": "ASO MA PFML",
  "MA PFML Fully Ins": "MA PFML Fully Ins",
  // WA PFML -- there is no Fully Insured for WA
  "ASO WA PFML": "ASO WA PFML",
  // CO PFML
  "ASO CO PFML": "ASO CO PFML",
  "CO PFML Fully Ins": "CO PFML Fully Ins",
  // OR PFML
  "ASO OR PFML": "ASO OR PFML",
  "OR PFML Fully Ins": "OR PFML Fully Ins",
  "AL DHMO": "Dental Prepaid Alabama",
  "AZ DHMO": "Dental Prepaid Arizona",
  "CA DHMO": "Dental Prepaid California",
  "CO DHMO": "Dental Prepaid Colorado",
  "FL DHMO": "Dental Prepaid Florida",
  "GA DHMO": "Dental Prepaid Georgia",
  "IL DHMO": "Dental Prepaid Illinois",
  "KS DHMO": "Dental Prepaid Kansas",
  "KY DHMO": "Dental Prepaid Kentucky",
  "MI DHMO": "Dental Prepaid Michigan",
  "MO DHMO": "Dental Prepaid Missouri",
  "NE DHMO": "Dental Prepaid Nebraska",
  "NJ DHMO": "Dental Prepaid New Jersey",
  "NM DHMO": "Dental Prepaid New Mexico",
  "NY DHMO": "Dental Prepaid New York",
  "OH DHMO": "Dental Prepaid Ohio",
  "OK DHMO": "Dental Prepaid Oklahoma",
  "PA DHMO": "Dental Prepaid Pennsylvania",
  "TN DHMO": "Dental Prepaid Tennessee",
  "TX DHMO": "Dental Prepaid Texas",
  "UT DHMO": "Dental Prepaid Utah",
  "WI DHMO": "Dental Prepaid Wisconsin",

  // FLI
  "Family Leave Insurance": "Family Leave Insurance",
} as const;

export type SlfCoverageName = keyof typeof slfCoverageNameToLongName;
export type SlfCoverageLongName = ValueOf<typeof slfCoverageNameToLongName>;
export const InforceCoveragesValues = Object.values(slfCoverageNameToLongName);
export type InforceCoveragesValue = (typeof InforceCoveragesValues)[number];

export const slfCoverageLongNameToShortName: Record<SlfCoverageLongName, SlfCoverageName> =
  swapKeyValues(slfCoverageNameToLongName);

export const coverageToBenefitType: Record<SlfCoverageLongName, BenefitTypeEIF> = {
  "Short Term Disability": "SHORT_TERM_DISABILITY",
  "Long Term Disability": "LONG_TERM_DISABILITY",
  "Basic Life": "LIFE",
  "Dependent Basic Life": "DEP_LIFE",
  "Basic AD&D": "BASIC_ADND",
  "Voluntary Life": "VOL_LIFE",
  "Voluntary AD&D": "VOL_ADND",
  "Stand-alone Vol AD&D": "STANDALONE_VOL_ADND",
  "Voluntary Short Term Disability": "VOL_STD",
  "Voluntary Long Term Disability": "VOL_LTD",
  Accident: "ACCIDENT",
  "Critical Illness": "CRITICAL_ILLNESS",
  "Hospital Indemnity": "HOSPITAL_INDEMNITY",
  Gap: "GAP",
  Dental: "DENTAL",
  "Dental (DHMO)": "DENTAL_DHMO",
  "ASO Dental": "ASO_DENTAL",
  Cancer: "CANCER",
  "Issue Age STD": "ISSUE_AGE_STD",
  Vision: "VISION",
  "Spouse Voluntary Life": "SPOUSE_VOL_LIFE",
  "Basic Dep AD&D": "BASIC_DEP_ADND",
  "Child Voluntary Life": "CHILDREN_VOL_LIFE",
  "Spouse Voluntary AD&D": "SPOUSE_VOL_ADND",
  "Child Voluntary AD&D": "CHILDREN_VOL_ADND",
  "Standalone Spouse Vol AD&D": "STANDALONE_SPOUSE_VOL_ADND",
  "Standalone Child Vol AD&D": "STANDALONE_CHILDREN_VOL_ADND",
  "Standalone Family Vol AD&D": "STANDALONE_FAMILY_VOL_ADND",
  "Absence & ADA Solutions": "OTHER",
  "New York Disability": "DBL",
  "New York Paid Family Leave": "PFL",
  "Family and Medical Leave Act (FMLA)": "OTHER",
  "New Jersey Temporary Disability": "TDB",
  "Hawaii Temporary Disability": "TDI",
  // CT PFML
  "ASO CT PFML": "PFML_CT_ASO",
  "CT PFML Fully Ins": "PFML_CT_FULLY_INS",
  // MA PFML
  "ASO MA PFML": "PFML_MA_ASO",
  "MA PFML Fully Ins": "PFML_MA_FULLY_INS",
  // WA PFML
  "ASO WA PFML": "PFML_WA_ASO",
  // CO PFML
  "ASO CO PFML": "PFML_CO_ASO",
  "CO PFML Fully Ins": "PFML_CO_FULLY_INS",
  // OR PFML
  "ASO OR PFML": "PFML_OR_ASO",
  "OR PFML Fully Ins": "PFML_OR_FULLY_INS",
  "Dental Prepaid Alabama": "AL_DHMO",
  "Dental Prepaid Arizona": "AZ_DHMO",
  "Dental Prepaid California": "CA_DHMO",
  "Dental Prepaid Colorado": "CO_DHMO",
  "Dental Prepaid Florida": "FL_DHMO",
  "Dental Prepaid Georgia": "GA_DHMO",
  "Dental Prepaid Illinois": "IL_DHMO",
  "Dental Prepaid Kansas": "KS_DHMO",
  "Dental Prepaid Kentucky": "KY_DHMO",
  "Dental Prepaid Michigan": "MI_DHMO",
  "Dental Prepaid Missouri": "MO_DHMO",
  "Dental Prepaid Nebraska": "NE_DHMO",
  "Dental Prepaid New Jersey": "NJ_DHMO",
  "Dental Prepaid New Mexico": "NM_DHMO",
  "Dental Prepaid New York": "NY_DHMO",
  "Dental Prepaid Ohio": "OH_DHMO",
  "Dental Prepaid Oklahoma": "OK_DHMO",
  "Dental Prepaid Pennsylvania": "PA_DHMO",
  "Dental Prepaid Tennessee": "TN_DHMO",
  "Dental Prepaid Texas": "TX_DHMO",
  "Dental Prepaid Utah": "UT_DHMO",
  "Dental Prepaid Wisconsin": "WI_DHMO",

  // FLI
  "Family Leave Insurance": "FLI",

  // SunAdvisor
  "SunAdvisor Advice Plus": "SUNADVISOR_ADVICE_PLUS",
  "SunAdvisor Advice-to-Pay": "SUNADVISOR_ADVICE_TO_PAY",
  "Employee Assistance Program": "EAP",
};

export const getIsSlfCoverageLongName = (item: string): item is SlfCoverageLongName => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.values(slfCoverageNameToLongName).includes(item as SlfCoverageLongName);
};

export const benefitTypeToCoverage: Record<BenefitTypeEIF, SlfCoverageLongName> =
  swapKeyValues(coverageToBenefitType);

// #region Coverage with dependencies
export const dependencyCoveragesMap: Record<SlfCoverageLongName, SlfCoverageLongName | null> = {
  // have dependencies
  "Dependent Basic Life": "Basic Life",
  "Basic Dep AD&D": "Basic AD&D",
  "Child Voluntary Life": "Voluntary Life",
  "Spouse Voluntary Life": "Voluntary Life",
  "Spouse Voluntary AD&D": "Voluntary AD&D",
  "Child Voluntary AD&D": "Voluntary AD&D",
  "Standalone Spouse Vol AD&D": "Stand-alone Vol AD&D",
  "Standalone Child Vol AD&D": "Stand-alone Vol AD&D",
  "Standalone Family Vol AD&D": "Stand-alone Vol AD&D",
  // does not have dependencies
  "Short Term Disability": null,
  "Long Term Disability": null,
  "Voluntary Short Term Disability": null,
  "Voluntary Long Term Disability": null,
  "Basic Life": null,
  "Basic AD&D": null,
  "Voluntary Life": null,
  "Voluntary AD&D": null,
  "Stand-alone Vol AD&D": null,
  Accident: null,
  "Critical Illness": null,
  "Hospital Indemnity": null,
  Gap: null,
  Dental: null,
  "Dental (DHMO)": null,
  "ASO Dental": null,
  Cancer: null,
  "Issue Age STD": null,
  Vision: null,
  "SunAdvisor Advice Plus": null,
  "SunAdvisor Advice-to-Pay": null,
  "Absence & ADA Solutions": null,
  "Employee Assistance Program": null,
  "New York Disability": null,
  "New York Paid Family Leave": null,
  "Family and Medical Leave Act (FMLA)": null,
  "New Jersey Temporary Disability": null,
  "Hawaii Temporary Disability": null,
  "ASO CT PFML": null,
  "CT PFML Fully Ins": null,
  "ASO MA PFML": null,
  "MA PFML Fully Ins": null,
  "ASO WA PFML": null,
  "ASO CO PFML": null,
  "CO PFML Fully Ins": null,
  "ASO OR PFML": null,
  "OR PFML Fully Ins": null,
  "Dental Prepaid Alabama": null,
  "Dental Prepaid Arizona": null,
  "Dental Prepaid California": null,
  "Dental Prepaid Colorado": null,
  "Dental Prepaid Florida": null,
  "Dental Prepaid Georgia": null,
  "Dental Prepaid Illinois": null,
  "Dental Prepaid Kansas": null,
  "Dental Prepaid Kentucky": null,
  "Dental Prepaid Michigan": null,
  "Dental Prepaid Missouri": null,
  "Dental Prepaid Nebraska": null,
  "Dental Prepaid New Jersey": null,
  "Dental Prepaid New Mexico": null,
  "Dental Prepaid New York": null,
  "Dental Prepaid Ohio": null,
  "Dental Prepaid Oklahoma": null,
  "Dental Prepaid Pennsylvania": null,
  "Dental Prepaid Tennessee": null,
  "Dental Prepaid Texas": null,
  "Dental Prepaid Utah": null,
  "Dental Prepaid Wisconsin": null,
  "Family Leave Insurance": null,
};

// #endregion Coverage with dependencies

// #region Life benefits

export type LifeCoverageEIF = Extract<
  SlfCoverageLongName,
  | "Basic Life"
  | "Dependent Basic Life"
  | "Basic AD&D"
  | "Voluntary Life"
  | "Voluntary AD&D"
  | "Stand-alone Vol AD&D"
>;

export const lifeCoveragesEIF: readonly LifeCoverageEIF[] = [
  "Basic Life",
  "Dependent Basic Life",
  "Basic AD&D",
  "Voluntary Life",
  "Voluntary AD&D",
  "Stand-alone Vol AD&D",
];

export function getIsLifeCoverageEIF(coverage: SlfCoverageLongName): coverage is LifeCoverageEIF {
  const isLifeCoverage = lifeCoveragesEIF.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as LifeCoverageEIF,
  );
  return isLifeCoverage;
}

export type LifeCoverageClaimsReady = Extract<
  SlfCoverageLongName,
  | "Basic Life"
  | "Dependent Basic Life"
  | "Basic AD&D"
  | "Voluntary Life"
  | "Voluntary AD&D"
  | "Stand-alone Vol AD&D"
  | "Child Voluntary Life"
  | "Spouse Voluntary Life"
>;

export const lifeCoveragesClaimsReady: readonly LifeCoverageClaimsReady[] = [
  "Basic Life",
  "Dependent Basic Life",
  "Basic AD&D",
  "Voluntary Life",
  "Voluntary AD&D",
  "Stand-alone Vol AD&D",
  "Child Voluntary Life",
  "Spouse Voluntary Life",
];

export function getIsLifeCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is LifeCoverageClaimsReady {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return lifeCoveragesClaimsReady.includes(coverage as LifeCoverageClaimsReady);
}

// #endregion Life benefits

// #region Supplemental Health and Gap coverages

export function getIsSupplementalHealthCoverage(
  coverage: SlfCoverageLongName,
): coverage is SupplementalHealthCoverage {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return supplementalHealthCoverages.includes(coverage as SupplementalHealthCoverage);
}

export function getIsSupplementalHealthAndGapCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is SupplementalHealthAndGapCoverageClaimsReady {
  return supplementalHealthAndGapCoveragesClaimsReady.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as SupplementalHealthAndGapCoverageClaimsReady,
  );
}

// #endregion Supplemental Health and Gap coverages

// #region AD&D coverages

export type ADNDCoverageClaimsReady = Extract<
  SlfCoverageLongName,
  | "Basic AD&D"
  | "Basic Dep AD&D"
  | "Voluntary AD&D"
  | "Child Voluntary AD&D"
  | "Stand-alone Vol AD&D"
  | "Spouse Voluntary AD&D"
  | "Standalone Child Vol AD&D"
  | "Standalone Family Vol AD&D"
  | "Standalone Spouse Vol AD&D"
>;

export const adndCoveragesClaimsReady: readonly ADNDCoverageClaimsReady[] = [
  "Basic AD&D",
  "Basic Dep AD&D",
  "Voluntary AD&D",
  "Child Voluntary AD&D",
  "Stand-alone Vol AD&D",
  "Spouse Voluntary AD&D",
  "Standalone Child Vol AD&D",
  "Standalone Family Vol AD&D",
  "Standalone Spouse Vol AD&D",
];

export function getIsADNDCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is ADNDCoverageClaimsReady {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return adndCoveragesClaimsReady.includes(coverage as ADNDCoverageClaimsReady);
}

// #endregion AD&D coverages

// #region Absence

export type AbsenceCoverageClaimsReady = Extract<SlfCoverageLongName, "Absence & ADA Solutions">;

export const absenceCoveragesClaimsReady: readonly AbsenceCoverageClaimsReady[] = [
  "Absence & ADA Solutions",
];

export function getIsAbsenceCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is AbsenceCoverageClaimsReady {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return absenceCoveragesClaimsReady.includes(coverage as AbsenceCoverageClaimsReady);
}

// #endregion Absence

// #region Statutory coverages

export type StatutoryCoverage = Extract<
  SlfCoverageLongName,
  | "New York Paid Family Leave"
  | "New York Disability"
  | "New Jersey Temporary Disability"
  | "Hawaii Temporary Disability"
>;

export const statutoryCoverages: readonly StatutoryCoverage[] = [
  "New York Paid Family Leave",
  "New York Disability",
  "New Jersey Temporary Disability",
  "Hawaii Temporary Disability",
];

export function getIsStatutoryCoverage(
  coverage: SlfCoverageLongName,
): coverage is StatutoryCoverage {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return statutoryCoverages.includes(coverage as StatutoryCoverage);
}

// #endregion Statutory coverages

// #region SunAdvisor coverages

export type SunAdvisorCoverages = Extract<
  SlfCoverageLongName,
  "SunAdvisor Advice Plus" | "SunAdvisor Advice-to-Pay" | "Employee Assistance Program"
>;

export const sunAdvisorCoverages: readonly SunAdvisorCoverages[] = [
  "SunAdvisor Advice Plus",
  "SunAdvisor Advice-to-Pay",
  "Employee Assistance Program",
];

export const sunAdvisorCoveragesWithMonthlyClaims: readonly SunAdvisorCoverages[] = [
  "SunAdvisor Advice Plus",
  "SunAdvisor Advice-to-Pay",
];

export function getIsSunAdvisorCoverage(
  coverage: SlfCoverageLongName,
): coverage is SunAdvisorCoverages {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return sunAdvisorCoverages.includes(coverage as SunAdvisorCoverages);
}

// #endregion SunAdvisor coverages

// #region Disability benefits
export type DisabilityCoverage = Extract<
  SlfCoverageLongName,
  | "Short Term Disability"
  | "Long Term Disability"
  | "Voluntary Short Term Disability"
  | "Voluntary Long Term Disability"
>;

export const disabilityCoverages: readonly DisabilityCoverage[] = [
  "Short Term Disability",
  "Long Term Disability",
  "Voluntary Short Term Disability",
  "Voluntary Long Term Disability",
];

export function getIsDisabilityCoverage(
  coverage: SlfCoverageLongName,
): coverage is DisabilityCoverage {
  const isDisabilityCoverage = disabilityCoverages.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as DisabilityCoverage,
  );
  return isDisabilityCoverage;
}

// #endregion Disability benefits

// #region Life and Disability benefits

export type LifeAndDisabilityCoverage = Extract<
  SlfCoverageLongName,
  | "Short Term Disability"
  | "Long Term Disability"
  | "Basic Life"
  | "Dependent Basic Life"
  | "Basic AD&D"
  | "Voluntary Short Term Disability"
  | "Voluntary Long Term Disability"
  | "Voluntary Life"
  | "Voluntary AD&D"
  | "Stand-alone Vol AD&D"
>;

export const lifeAndDisabilityCoverages: readonly LifeAndDisabilityCoverage[] = [
  "Short Term Disability",
  "Long Term Disability",
  "Basic Life",
  "Dependent Basic Life",
  "Basic AD&D",
  "Voluntary Short Term Disability",
  "Voluntary Long Term Disability",
  "Voluntary Life",
  "Voluntary AD&D",
  "Stand-alone Vol AD&D",
];

export function getIsLifeAndDisabilityCoverage(
  coverage: SlfCoverageLongName,
): coverage is LifeAndDisabilityCoverage {
  const isLifeAndDisabilityCoverage = lifeAndDisabilityCoverages.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as LifeAndDisabilityCoverage,
  );
  return isLifeAndDisabilityCoverage;
}

// #endregion Life and Disability benefits

// #region PFML benefits

export type PFMLCoverage = Extract<
  SlfCoverageLongName,
  | "ASO CT PFML"
  | "CT PFML Fully Ins"
  | "ASO MA PFML"
  | "MA PFML Fully Ins"
  | "ASO WA PFML"
  | "ASO CO PFML"
  | "CO PFML Fully Ins"
  | "ASO OR PFML"
  | "OR PFML Fully Ins"
>;

export const pfmlCoverages: readonly PFMLCoverage[] = [
  "ASO CT PFML",
  "CT PFML Fully Ins",
  "ASO MA PFML",
  "MA PFML Fully Ins",
  "ASO WA PFML",
  "ASO CO PFML",
  "CO PFML Fully Ins",
  "ASO OR PFML",
  "OR PFML Fully Ins",
];

export type DHMOCoverages = Extract<
  SlfCoverageLongName,
  | "Dental Prepaid Alabama"
  | "Dental Prepaid Arizona"
  | "Dental Prepaid California"
  | "Dental Prepaid Colorado"
  | "Dental Prepaid Florida"
  | "Dental Prepaid Georgia"
  | "Dental Prepaid Illinois"
  | "Dental Prepaid Kansas"
  | "Dental Prepaid Kentucky"
  | "Dental Prepaid Michigan"
  | "Dental Prepaid Missouri"
  | "Dental Prepaid Nebraska"
  | "Dental Prepaid New Jersey"
  | "Dental Prepaid New Mexico"
  | "Dental Prepaid New York"
  | "Dental Prepaid Ohio"
  | "Dental Prepaid Oklahoma"
  | "Dental Prepaid Pennsylvania"
  | "Dental Prepaid Tennessee"
  | "Dental Prepaid Texas"
  | "Dental Prepaid Utah"
  | "Dental Prepaid Wisconsin"
>;

export const dhmoCoverages: readonly DHMOCoverages[] = [
  "Dental Prepaid Alabama",
  "Dental Prepaid Arizona",
  "Dental Prepaid California",
  "Dental Prepaid Colorado",
  "Dental Prepaid Florida",
  "Dental Prepaid Georgia",
  "Dental Prepaid Illinois",
  "Dental Prepaid Kansas",
  "Dental Prepaid Kentucky",
  "Dental Prepaid Michigan",
  "Dental Prepaid Missouri",
  "Dental Prepaid Nebraska",
  "Dental Prepaid New Jersey",
  "Dental Prepaid New Mexico",
  "Dental Prepaid New York",
  "Dental Prepaid Ohio",
  "Dental Prepaid Oklahoma",
  "Dental Prepaid Pennsylvania",
  "Dental Prepaid Tennessee",
  "Dental Prepaid Texas",
  "Dental Prepaid Utah",
  "Dental Prepaid Wisconsin",
];

export function getIsPFMLCoverage(coverage: SlfCoverageLongName): coverage is PFMLCoverage {
  const isPFMLCoverage = pfmlCoverages.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as PFMLCoverage,
  );
  return isPFMLCoverage;
}

// #endregion PFML benefits

// #region Family Leave Insurance
export function getIsFLICoverage(coverage: SlfCoverageLongName) {
  return coverage === "Family Leave Insurance";
}
// #endregion Family Leave Insurance

// #region EAP
export function getIsEAPCoverage(coverage: SlfCoverageLongName) {
  return coverage === "Employee Assistance Program";
}
// #endregion EAP

// #region Vision coverages

export type VisionCoverageClaimsReady = Extract<SlfCoverageLongName, "Vision">;

export const visionCoveragesClaimsReady: readonly VisionCoverageClaimsReady[] = ["Vision"];

export function getIsVisionCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is VisionCoverageClaimsReady {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return visionCoveragesClaimsReady.includes(coverage as VisionCoverageClaimsReady);
}

// #endregion Vision coverages

// #region SunAdvisor coverages Claims Ready

export type SunAdvisorCoverageClaimsReady = Extract<
  SlfCoverageLongName,
  "SunAdvisor Advice Plus" | "SunAdvisor Advice-to-Pay"
>;

export const sunAdvisorCoveragesClaimsReady: readonly SunAdvisorCoverageClaimsReady[] = [
  "SunAdvisor Advice Plus",
  "SunAdvisor Advice-to-Pay",
];

export function getIsSunAdvisorCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is SunAdvisorCoverageClaimsReady {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return sunAdvisorCoveragesClaimsReady.includes(coverage as SunAdvisorCoverageClaimsReady);
}

// #endregion SunAdvisor coverages Claims Ready

// #region Short Term Disability coverages

export type ShortTermDisabilityCoverageClaimsReady = Extract<
  SlfCoverageLongName,
  "Short Term Disability" | "Voluntary Short Term Disability"
>;

export const shortTermDisabilityCoveragesClaimsReady: readonly ShortTermDisabilityCoverageClaimsReady[] =
  ["Short Term Disability", "Voluntary Short Term Disability"];

export function getIsShortTermDisabilityCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is ShortTermDisabilityCoverageClaimsReady {
  return shortTermDisabilityCoveragesClaimsReady.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as ShortTermDisabilityCoverageClaimsReady,
  );
}

// #endregion Short Term Disability coverages

// #region Long Term Disability coverages

export type LongTermDisabilityCoverageClaimsReady = Extract<
  SlfCoverageLongName,
  "Long Term Disability" | "Voluntary Long Term Disability"
>;

export const longTermDisabilityCoveragesClaimsReady: readonly LongTermDisabilityCoverageClaimsReady[] =
  ["Long Term Disability", "Voluntary Long Term Disability"];

export function getIsLongTermDisabilityCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is LongTermDisabilityCoverageClaimsReady {
  return longTermDisabilityCoveragesClaimsReady.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as LongTermDisabilityCoverageClaimsReady,
  );
}

// #endregion Long Term Disability coverages

// #region Dental coverages

export type DentalCoverageClaimsReady = Extract<
  SlfCoverageLongName,
  "Dental" | "Dental (DHMO)" | "ASO Dental"
>;

export const DentalCoverageNames: SlfCoverageName[] = ["Dental", "Dental (DHMO)", "ASO Dental"];

export const dentalCoveragesClaimsReady: readonly DentalCoverageClaimsReady[] = [
  "Dental",
  "Dental (DHMO)",
  "ASO Dental",
];

export function getIsDentalCoverageClaimsReady(
  coverage: SlfCoverageLongName,
): coverage is DentalCoverageClaimsReady {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return dentalCoveragesClaimsReady.includes(coverage as DentalCoverageClaimsReady);
}

// #endregion Dental coverages

// #region PFML ASO coverages

export type PFMLASOCoverage = Extract<
  SlfCoverageLongName,
  "ASO CT PFML" | "ASO MA PFML" | "ASO WA PFML" | "ASO CO PFML" | "ASO OR PFML"
>;

export const pfmlASOCoveragesSet = new Set<PFMLASOCoverage>([
  "ASO CT PFML",
  "ASO MA PFML",
  "ASO WA PFML",
  "ASO CO PFML",
  "ASO OR PFML",
]);

export const pfmlASOCoverages: readonly PFMLASOCoverage[] = [
  "ASO CT PFML",
  "ASO MA PFML",
  "ASO WA PFML",
  "ASO CO PFML",
  "ASO OR PFML",
];

export function getIsPFMLASOCoverage(coverage: SlfCoverageLongName): coverage is PFMLASOCoverage {
  const isPFMLASOCoverage = pfmlASOCoveragesSet.has(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as PFMLASOCoverage,
  );
  return isPFMLASOCoverage;
}

// #endregion PFML ASO coverages

// #region Benefits with Claims

const coveragesWithMonthlyClaims: Set<SlfCoverageLongName> = new Set([
  "Short Term Disability",
  "Long Term Disability",
  "Voluntary Short Term Disability",
  "Voluntary Long Term Disability",
  "Family Leave Insurance",
  ...statutoryCoverages,
  ...pfmlCoverages,
  ...sunAdvisorCoveragesWithMonthlyClaims,
]);

export function getCoverageHasMonthlyClaim(coverage: SlfCoverageLongName) {
  const hasMonthlyClaim = coveragesWithMonthlyClaims.has(coverage);
  return hasMonthlyClaim;
}

// #endregion Benefits with Claims

// #region Age Related Coverages

export type AgeRelatedSlfCoverages = Extract<
  SlfCoverageLongName,
  | "Short Term Disability"
  | "Long Term Disability"
  | "Basic Life"
  | "Voluntary Life"
  | "Voluntary Short Term Disability"
  | "Voluntary Long Term Disability"
  | "Critical Illness"
>;

export const ageRelatedSlfCoverages: readonly AgeRelatedSlfCoverages[] = [
  "Short Term Disability",
  "Long Term Disability",
  "Basic Life",
  "Voluntary Life",
  "Voluntary Short Term Disability",
  "Voluntary Long Term Disability",
  "Critical Illness",
];

// #endregion Age Related Coverages

// #region Salary Based Coverage

export type SalaryBasedCoverage = Extract<
  SlfCoverageLongName,
  | "Short Term Disability"
  | "Long Term Disability"
  | "Basic Life"
  | "Basic AD&D"
  | "Voluntary Short Term Disability"
  | "Voluntary Long Term Disability"
  | "Voluntary Life"
  | "Voluntary AD&D"
  | "Stand-alone Vol AD&D"
  | "Family Leave Insurance"
>;

export const salaryBasedCoverages: readonly SalaryBasedCoverage[] = [
  "Short Term Disability",
  "Long Term Disability",
  "Basic Life",
  "Basic AD&D",
  "Voluntary Short Term Disability",
  "Voluntary Long Term Disability",
  "Voluntary Life",
  "Voluntary AD&D",
  "Stand-alone Vol AD&D",
  "Family Leave Insurance",
];

export function getIsSalaryBasedCoverage(
  coverage: SlfCoverageLongName,
): coverage is SalaryBasedCoverage {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const isSalaryBasedCoverage = salaryBasedCoverages.includes(coverage as SalaryBasedCoverage);
  return isSalaryBasedCoverage;
}

// #endregion Salary Based Coverage

// #region Supplemental health

export type SupplementalHealthCoverage = Extract<
  SlfCoverageLongName,
  "Accident" | "Cancer" | "Critical Illness" | "Hospital Indemnity"
>;

export type SupplementalHealthAndGapCoverageClaimsReady = Extract<
  SlfCoverageLongName,
  "Accident" | "Cancer" | "Critical Illness" | "Hospital Indemnity" | "Gap"
>;

export const supplementalHealthCoverages: readonly SupplementalHealthCoverage[] = [
  "Accident",
  "Cancer",
  "Critical Illness",
  "Hospital Indemnity",
];

export const supplementalHealthAndGapCoveragesClaimsReady: readonly SupplementalHealthAndGapCoverageClaimsReady[] =
  ["Accident", "Cancer", "Critical Illness", "Hospital Indemnity", "Gap"];

// #endregion Supplemental health

// #region Benefits not in a Section 125 plan

export type CoverageNotIncludedInSection125Plan =
  | LifeAndDisabilityCoverage
  | PFMLCoverage
  | SupplementalHealthCoverage;

export const coveragesNotIncludedInSection125Plan: readonly CoverageNotIncludedInSection125Plan[] =
  [...lifeAndDisabilityCoverages, ...pfmlCoverages, ...supplementalHealthCoverages];

export function getIsCoverageNotIncludedInSection125Plan(
  coverage: string,
): coverage is CoverageNotIncludedInSection125Plan {
  const isIt = coveragesNotIncludedInSection125Plan.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as CoverageNotIncludedInSection125Plan,
  );
  return isIt;
}

// #endregion Benefits not in a Section 125 plan

// #region Advance & Arrears

export type AdvanceCoverage = Extract<
  SlfCoverageLongName,
  | "Dental"
  | "Vision"
  | "Basic Life"
  | "Dependent Basic Life"
  | "Basic AD&D"
  | "Short Term Disability"
  | "Long Term Disability"
  | "Dental (DHMO)"
  | "Family Leave Insurance"
  | "ASO Dental"
  | StatutoryCoverage
  | SunAdvisorCoverages
>;

export const advanceCoverages: readonly AdvanceCoverage[] = [
  "Dental",
  "Vision",
  "Basic Life",
  "Dependent Basic Life",
  "Basic AD&D",
  "Short Term Disability",
  "Long Term Disability",
  "Dental (DHMO)",
  "Family Leave Insurance",
  "ASO Dental",
  ...statutoryCoverages,
  ...sunAdvisorCoverages,
];

export function getIsAdvanceCoverage(coverage: SlfCoverageLongName): coverage is AdvanceCoverage {
  const isIt = advanceCoverages.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as AdvanceCoverage,
  );
  return isIt;
}

export type ArrearCoverage = SupplementalHealthCoverage;

export const arrearCoverages: readonly ArrearCoverage[] = supplementalHealthCoverages;

export function getIsArrearsCoverage(coverage: SlfCoverageLongName): coverage is ArrearCoverage {
  const isIt = arrearCoverages.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as ArrearCoverage,
  );
  return isIt;
}

export type AdvanceOrArrearsCoverage = Extract<
  SlfCoverageLongName,
  | "Voluntary Life"
  | "Voluntary AD&D"
  | "Voluntary Short Term Disability"
  | "Voluntary Long Term Disability"
  | "Stand-alone Vol AD&D"

  // PFML
  | "ASO CT PFML"
  | "CT PFML Fully Ins"
  | "ASO MA PFML"
  | "MA PFML Fully Ins"
  | "ASO WA PFML"
  | "ASO CO PFML"
  | "CO PFML Fully Ins"
  | "ASO OR PFML"
  | "OR PFML Fully Ins"
>;

export const advanceOrArrearCoverages: readonly AdvanceOrArrearsCoverage[] = [
  "Voluntary Life",
  "Voluntary AD&D",
  "Voluntary Short Term Disability",
  "Voluntary Long Term Disability",
  "Stand-alone Vol AD&D",

  // PFML
  "ASO CT PFML",
  "CT PFML Fully Ins",
  "ASO MA PFML",
  "MA PFML Fully Ins",
  "ASO WA PFML",
  "ASO CO PFML",
  "CO PFML Fully Ins",
  "ASO OR PFML",
  "OR PFML Fully Ins",
];

export function getIsAdvanceOrArrearsCoverage(
  coverage: SlfCoverageLongName,
): coverage is AdvanceOrArrearsCoverage;
export function getIsAdvanceOrArrearsCoverage(
  coverage: string,
): coverage is AdvanceOrArrearsCoverage;
export function getIsAdvanceOrArrearsCoverage(
  coverage: string,
): coverage is AdvanceOrArrearsCoverage {
  const isIt = advanceOrArrearCoverages.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    coverage as AdvanceOrArrearsCoverage,
  );
  return isIt;
}

// #endregion Advance & Arrears

export const annualEnrollmentRequiredCoverages = new Set<SlfCoverageLongName>(["Dental", "Vision"]);

export type CoverageSupportedInEIF = Extract<
  SlfCoverageLongName,
  | "Dental"
  | "Vision"
  | LifeAndDisabilityCoverage
  | SupplementalHealthCoverage
  | PFMLCoverage
  | "ASO Dental"
  | "Dental (DHMO)"
  | DHMOCoverages
  | "Family Leave Insurance"
  | StatutoryCoverage
  | SunAdvisorCoverages
>;

export const coveragesSupportedInEIF: Set<CoverageSupportedInEIF> = new Set([
  "Dental",
  "Vision",
  ...lifeAndDisabilityCoverages,
  ...supplementalHealthCoverages,
  ...pfmlCoverages,
  "Dental (DHMO)",
  "ASO Dental",
  ...dhmoCoverages,
  "Family Leave Insurance",
  ...statutoryCoverages,
  ...sunAdvisorCoverages,
]);

export function getIsCoverageSupportedInEIF(
  coverage: SlfCoverageLongName,
): coverage is CoverageSupportedInEIF {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const isIt = coveragesSupportedInEIF.has(coverage as CoverageSupportedInEIF);
  return isIt;
}

export function getIsDHMOCoverage(coverage: SlfCoverageLongName): coverage is DHMOCoverages {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const isIt = dhmoCoverages.includes(coverage as DHMOCoverages);
  return isIt;
}

export const dentalVisionCoverage: Extract<SlfCoverageLongName, "Dental" | "Vision">[] = [
  "Dental",
  "Vision",
];

export type BeneficiaryDesignationCoverages = Extract<
  SlfCoverageLongName,
  "Basic Life" | "Voluntary Life" | "Stand-alone Vol AD&D"
>;

export const beneficiaryDesignationCoverages: readonly BeneficiaryDesignationCoverages[] = [
  "Basic Life",
  "Voluntary Life",
  "Stand-alone Vol AD&D",
];

export type CoverageSupportedInClassBuilder = Extract<
  SlfCoverageLongName,
  | "Dental"
  | "ASO Dental"
  | "Vision"
  | LifeAndDisabilityCoverage
  | SupplementalHealthCoverage
  | "Dental (DHMO)"
  | "Family Leave Insurance"
>;

export type CoverageSupportedInClassBuilderSunAdvisorFtOn = Extract<
  SlfCoverageLongName,
  | "Dental"
  | "ASO Dental"
  | "Vision"
  | LifeAndDisabilityCoverage
  | SupplementalHealthCoverage
  | "Dental (DHMO)"
  | "Family Leave Insurance"
  | SunAdvisorCoverages
  | "Employee Assistance Program"
>;

export const coveragesSupportedInClassBuilder: Set<CoverageSupportedInClassBuilder> = new Set([
  "Dental",
  "ASO Dental",
  "Vision",
  ...lifeAndDisabilityCoverages,
  ...supplementalHealthCoverages,
  "Dental (DHMO)",
  "Family Leave Insurance",
]);

export const coveragesSupportedInClassBuilderSunAdvisorFtOn: Set<CoverageSupportedInClassBuilderSunAdvisorFtOn> =
  new Set([
    "Dental",
    "ASO Dental",
    "Vision",
    ...lifeAndDisabilityCoverages,
    ...supplementalHealthCoverages,
    "Dental (DHMO)",
    "Family Leave Insurance",
    ...sunAdvisorCoverages,
    "Employee Assistance Program",
  ]);

export function getIsCoverageSupportedInClassBuilder(
  coverage: SlfCoverageLongName,
  plans: Plan[],
  featureToggles: ClientFeatureToggles,
): coverage is CoverageSupportedInClassBuilder {
  const relevantCoverages = featureToggles.ONBOARD_DSF_SUNADVISOR
    ? coveragesSupportedInClassBuilderSunAdvisorFtOn
    : coveragesSupportedInClassBuilder;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const isIt = relevantCoverages.has(coverage as CoverageSupportedInClassBuilder);

  if (isIt && coverage === "Family Leave Insurance") {
    const fliPlan = plans.find((plan) => plan.benefitType === "FLI");
    return fliPlan?.salaryBased ?? false;
  }

  return isIt;
}

export function getIsDBLorPFL(policy: Policy) {
  const isDBLorPFL =
    policy.slfCoverages?.some(
      (coverage) => coverage === "New York Disability" || coverage === "New York Paid Family Leave",
    ) ?? false;
  return isDBLorPFL;
}

export function getIsHIorNJ(policy: Policy) {
  const isHIorNJ =
    policy.slfCoverages?.some(
      (coverage) =>
        coverage === "Hawaii Temporary Disability" ||
        coverage === "New Jersey Temporary Disability",
    ) ?? false;
  return isHIorNJ;
}

export const getHasCoverageWithoutDependenciesMetMsg = (
  allSlfCoverages: SlfCoverageLongName[],
): string | null => {
  for (const slfCoverage of allSlfCoverages) {
    const requiredCoverage = dependencyCoveragesMap[slfCoverage];
    if (requiredCoverage == null) {
      continue;
    }

    const hasRequiredCoverage = allSlfCoverages.some(
      (slfCoverage) => slfCoverage === requiredCoverage,
    );
    if (!hasRequiredCoverage) {
      return `${slfCoverage} has been selected without ${requiredCoverage}. Employee coverage is a requirement to have dependent coverage.`;
    }
  }

  return null;
};
