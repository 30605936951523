import { useParams } from "react-router";

import { type ValidParams, type ParamToType } from "shared/config/routeData";
import { isOutsideSignerActionId } from "shared/types/OutsideSigner";
import { isTaskId } from "shared/utils/client";
import type { TaskId } from "shared/data/Tasks";
import type { OutsideSignerActionId } from "shared/types/OutsideSigner";

export const useClientHubParams = <T extends readonly (keyof ValidParams)[] = []>(required?: T) => {
  const params = useParams<Record<keyof ParamToType, string>>();

  const taskId =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    params.taskId && isTaskId(params.taskId as TaskId) ? params.taskId : undefined;
  const clientId = params.clientId ?? undefined;
  const onboardingFormId = params.onboardingFormId ?? undefined;
  const explorerPageId = params.explorerPageId ?? undefined;
  const eifStepId = params.eifStepId ?? undefined;
  const eifSubStepId = params.eifSubStepId ?? undefined;
  const employeeClassId = params.employeeClassId ?? undefined;
  const emailId = params.emailId ?? undefined;
  const documentId = params.documentId ?? undefined;

  const outsideSignerActionId =
    params.outsideSignerActionId &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    isOutsideSignerActionId(params.outsideSignerActionId as OutsideSignerActionId)
      ? params.outsideSignerActionId
      : undefined;

  if (required?.includes("taskId") && !taskId) {
    throw new Error("Param taskId is required but not provided!");
  }
  if (required?.includes("clientId") && !clientId) {
    throw new Error("Param clientId is required but not provided!");
  }
  if (required?.includes("onboardingFormId") && !onboardingFormId) {
    throw new Error("Param onboardingFormId is required but not provided!");
  }
  if (required?.includes("explorerPageId") && !explorerPageId) {
    throw new Error("Param explorerPageId is required but not provided!");
  }
  if (required?.includes("eifStepId") && !eifStepId) {
    throw new Error("Param eifStepId is required but not provided!");
  }
  if (required?.includes("eifSubStepId") && !eifStepId) {
    throw new Error("Param eifSubStepId is required but not provided!");
  }
  if (required?.includes("employeeClassId") && !employeeClassId) {
    throw new Error("Param employeeClassId is required but not provided!");
  }
  if (required?.includes("emailId") && !emailId) {
    throw new Error("Param emailId is required but not provided!");
  }
  if (required?.includes("documentId") && !documentId) {
    throw new Error("Param documentId is required but not provided!");
  }
  if (required?.includes("outsideSignerActionId") && !outsideSignerActionId) {
    throw new Error("Param outsideSignerActionId is required but not provided!");
  }

  const result = {
    ...params,
    onboardingFormId,
    taskId,
    clientId,
    explorerPageId,
    eifStepId,
    eifSubStepId,
    employeeClassId,
    emailId,
    documentId,
    outsideSignerActionId,
  };

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we're doing the checks above
  return result as unknown as Omit<ValidParams, T[number]> & Required<Pick<ValidParams, T[number]>>;
};
