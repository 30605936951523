import { getURLSearchParamsFromObject } from "client/src/utils/url";

export type TableQueryParams<
  ExtraSearchParams extends Record<string, unknown> = Record<string, unknown>,
> = {
  page: number;
  search: string;
  pageSize: number;
  sort?: { sortBy: string; direction: "asc" | "desc" };
} & ExtraSearchParams;

export const getTableQueryArguments = ({
  page,
  search,
  sort,
  pageSize,
  ...rest
}: TableQueryParams): string => {
  const limit = pageSize;
  const offset = (page - 1) * pageSize;

  const params = getURLSearchParamsFromObject({
    limit: String(limit),
    offset: String(offset),
    search,
    ...(sort && { sortBy: sort.sortBy, sortDirection: sort.direction }),
    ...rest,
  });

  params.sort();

  return params.toString();
};
