import { format } from "client/src/i18n/format";
import { AsyncBackend } from "client/src/i18n/i18next-async-backend";
import { defaultLocale, LocaleKeys } from "client/src/i18n/locales";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  initReactI18next,
  Trans as ReactTrans,
  useTranslation as useReactTranslation,
} from "react-i18next";

import type { BaseLocale, Locale } from "client/src/i18n/locales";
import type { UseTranslationOptions } from "react-i18next";

async function initI18N() {
  // note: english locale doesn't need translation because the keys are the english copy
  const resources: { [k in Locale]: { translation: () => Promise<BaseLocale> } } = {
    en: {
      translation: () => import("./locales/en").then((m) => m.english),
    },
    es: {
      translation: () => import("./locales/es").then((m) => m.spanish),
    },
  };

  await i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(AsyncBackend)
    .init({
      backend: { resources },
      lng: getPathLocale(),
      fallbackLng: defaultLocale,
      interpolation: {
        escapeValue: false,
        format,
      },
      react: {
        transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "sup"],
      },
      detection: {
        order: ["querystring", "localStorage", "sessionStorage", "navigator"],
      },
    });
}

void initI18N();

export type LocaleKey = keyof BaseLocale;

export const i18n = i18next;
export const Trans = ReactTrans;
export const useTranslation = (options?: UseTranslationOptions<string>) =>
  useReactTranslation(undefined, options);

function getPathLocale(): Locale | undefined {
  return LocaleKeys.find((localeKey) => window.location.pathname.startsWith(`/${localeKey}/`));
}
