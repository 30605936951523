import { isBetween } from "client/src/utils/dateUtils";
import type { ExplorerPage } from "shared/types/ExplorerPage";

export function getShowExplorerPageEnrollButton(explorerPage: ExplorerPage): boolean {
  return !!(
    explorerPage.benAdminPlatformUrl != null &&
    explorerPage.openEnrollmentStart &&
    explorerPage.openEnrollmentEnd &&
    isBetween(explorerPage.openEnrollmentStart, explorerPage.openEnrollmentEnd)
  );
}
