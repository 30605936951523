import { assertIsDefined } from "shared/utils/utils";

export function getObjectFromQueryString(queryString: string) {
  const url = new URLSearchParams(queryString);
  const object = getObjectFromURLSearchParams(url);
  return object;
}

export function getObjectFromURLSearchParams(url: URLSearchParams) {
  const object: Record<string, string | boolean | string[]> = {};
  for (const key of url.keys()) {
    if (key.endsWith("[]")) {
      object[key.substring(0, key.length - 2)] = url.getAll(key);
    } else {
      const values = url.getAll(key);
      if (values.length === 1) {
        const [value] = values;
        assertIsDefined(value, "value");
        object[key] = value === "true" ? true : value === "false" ? false : value;
      } else {
        object[key] = values;
      }
    }
  }

  return object;
}

export function getURLSearchParamsFromObject<T extends Record<string, unknown>>(object: T) {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(object)) {
    if (Array.isArray(value)) {
      for (const item of value) {
        params.append(`${key}[]`, item);
      }
    } else if (value != null) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any -- 😇
      params.append(key, value as any);
    }
  }

  params.sort();

  return params;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addNamespaceToParams(paramsObject: Record<string, any>, namespace?: string) {
  if (!namespace) {
    return paramsObject;
  }
  const namespacePrefix = namespace + ".";
  for (const propertyName of Object.getOwnPropertyNames(paramsObject)) {
    paramsObject[`${namespacePrefix}${propertyName}`] = paramsObject[propertyName];
    delete paramsObject[propertyName];
  }
  return paramsObject;
}

export const getParamsForNamespace = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsObject: Record<string, any>,
  namespace?: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const otherParams: Record<string, any> = {};
  if (!namespace) {
    return {
      paramsForNamespace: paramsObject,
      otherParams,
    };
  }
  const namespacePrefix = namespace + ".";
  for (const propertyName of Object.getOwnPropertyNames(paramsObject)) {
    if (propertyName.startsWith(namespacePrefix)) {
      const propertyNameWithoutNamespace = propertyName.split(".")[1];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      paramsObject[propertyNameWithoutNamespace!] = paramsObject[propertyName];
    } else {
      otherParams[propertyName] = paramsObject[propertyName];
    }
    delete paramsObject[propertyName];
  }
  return {
    paramsForNamespace: paramsObject,
    otherParams,
  };
};
