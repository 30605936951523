import {
  LocationStateCodes,
  locationTypes,
  Territories,
  type LocationStateCode,
  type Territory,
} from "shared/types/Location";
import * as Yup from "yup";
import type { LocationType } from "@prisma/client";

export const zipCodeRegex = /^\d{5}(-\d{4})?$/;
export const zipCodeErrorMessage = "Zip code needs to be in the format ##### or #####-####";

export const zipCodeValidation = Yup.string().trim().matches(zipCodeRegex, zipCodeErrorMessage);

export const locationStateCodeValidation = Yup.mixed<LocationStateCode>()
  .oneOf<LocationStateCode>(LocationStateCodes, "State not valid")
  .strict();

export const locationStateCodeWithNullValidation = Yup.mixed<LocationStateCode>()
  .oneOf<LocationStateCode>([...LocationStateCodes], "State is required")
  .nullable()
  .defined();

export const locationInputValidation = Yup.object({
  id: Yup.string().optional(),
  name: Yup.string()
    .trim()
    .nullable()
    .defined()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Location name is required"),
    }),
  address1: Yup.string()
    .trim()
    .nullable()
    .defined()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Address is required"),
    }),
  address2: Yup.string().trim().nullable().default(null),
  city: Yup.string()
    .trim()
    .nullable()
    .defined()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("City is required"),
    }),
  state: locationStateCodeWithNullValidation.when("$prefill", {
    is: (prefill: boolean | undefined) => !prefill,
    then: () => locationStateCodeValidation.required("State is required"),
    otherwise: (schema) => schema.transform((val: string | null) => (val === "" ? null : val)),
  }),
  zipCode: zipCodeValidation
    .nullable()
    .defined()
    .transform((val: string | undefined) => (val ? val : null))
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Zip code is required"),
    }),
  locationType: Yup.mixed<LocationType>().oneOf<LocationType>(locationTypes).nullable().defined(),
}).noUnknown();

export const territoryValidation = Yup.mixed<Territory>()
  .oneOf<Territory>(Territories, "Territory not valid")
  .strict();
