import { useGetPublicExplorerPageById } from "client/src/hooks/ExplorerPage/useExplorerPages";
import { match } from "client/src/hooks/route";
import { useHubConfiguration } from "client/src/hooks/useConfig";

import { useTrackZendeskClicked } from "client/src/utils/analytics";
import { getCSSVariableValue } from "client/src/utils/getCSSVariableValue";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router";
import { VIEW_TYPE } from "shared/config/routeData";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import type { Location } from "react-router";
import type { PublicExplorerPage } from "shared/types/ExplorerPage";

const benefitExplorerZendeskDepartmentName = "Benefits Explorer Live";
const demoBenefitExplorerZendeskDepartmentName = "Benefits Explorer Demo";
type ZendeskDepartmentName = "Benefits Explorer Demo" | "Benefits Explorer Live";

export function useZendeskWidget() {
  useCheckIfZendeskLoaded();

  useTrackZendeskClicked();

  useEffect(() => {
    const colorBlueMediumLight = getCSSVariableValue("--color-tertiary-700");

    window.zE?.("webWidget", "updateSettings", {
      webWidget: {
        launcher: {
          label: {
            "*": "Get Help",
          },
        },
        color: {
          articleLinks: colorBlueMediumLight,
          resultLists: colorBlueMediumLight,
        },
      },
    });
  }, []);

  const deptName = useZendeskDepartmentName();
  const deptStatus = useZendeskDepartmentStatus();
  useEffect(() => {
    switch (deptName) {
      case benefitExplorerZendeskDepartmentName:
      case demoBenefitExplorerZendeskDepartmentName:
        if (deptStatus === "online") {
          window.zE?.("webWidget", "updateSettings", {
            webWidget: {
              chat: {
                departments: {
                  enabled: [],
                  select: deptName,
                },
                suppress: false,
              },
              helpCenter: {
                suppress: true,
              },
              offset: {
                horizontal: "164px",
                mobile: {
                  vertical: "0px",
                  horizontal: "0px",
                },
              },
            },
          });
        } else {
          window.zE?.("webWidget", "updateSettings", {
            webWidget: {
              chat: {
                suppress: true,
              },
              helpCenter: {
                suppress: true,
              },
            },
          });
        }
        break;

      case "":
        window.zE?.("webWidget", "updateSettings", {
          webWidget: {
            chat: {
              suppress: true,
            },
            offset: {
              horizontal: "0px",
              mobile: {
                vertical: "0px",
                horizontal: "0px",
              },
            },
          },
        });
        break;

      default:
        exhaustiveCheck(deptName);
    }
  }, [deptStatus, deptName]);
}

function useCheckIfZendeskLoaded() {
  useEffect(() => {
    const timeout = 6000;
    const id = setTimeout(() => {
      const zendeskApiLoaded = Boolean(window.zE);
      if (!zendeskApiLoaded) {
        console.warn("Zendesk widget did not load.");
      }
    }, timeout);

    return () => {
      clearTimeout(id);
    };
  }, []);
}

export const getDeptName = (
  page: PublicExplorerPage | undefined,
  IS_BENEX_DEMO_ENVIRONMENT: boolean,
) => {
  if (!IS_BENEX_DEMO_ENVIRONMENT && page?.isLive) {
    return benefitExplorerZendeskDepartmentName;
  }
  return demoBenefitExplorerZendeskDepartmentName;
};

const useZendeskDepartmentName = (): ZendeskDepartmentName | "" => {
  const location = useLocation();
  const route = match(true, location.pathname);
  const { data: page } = useGetPublicExplorerPageById(route?.params.explorerPageId ?? "");
  const { IS_BENEX_DEMO_ENVIRONMENT } = useHubConfiguration();
  if (route?.viewType !== VIEW_TYPE.explorer) {
    return "";
  }

  return getDeptName(page, IS_BENEX_DEMO_ENVIRONMENT);
};

const subscribeToZendeskDepartmentStatus = (function () {
  const departments: Array<ZendeskDepartment> = [];

  const listeners: Array<(department: ZendeskDepartment) => void> = [];

  window.zE?.("webWidget:on", "chat:departmentStatus", (dept) => {
    const timestamp = new Date().toUTCString();
    const params = new URLSearchParams(window.location.search);
    if (params.has("debug")) {
      console.info(
        `${timestamp} - Zendesk Department "%c${dept.name}%c" (id: ${dept.id}) is now "%c${dept.status}%c"`,
        "font-weight: bold",
        "",
        dept.status === "online"
          ? "font-weight: bold; color: hsl(120deg 100% 30%);"
          : "font-weight: bold; color: red;",
        "",
      );
    }

    const i = departments.findIndex((d) => d.id === dept.id);
    if (i >= 0) departments.splice(i, 1);

    departments.push(dept);

    for (const listener of listeners) {
      listener(dept);
    }
  });

  return function subscribe(listener: (dept: ZendeskDepartment) => void) {
    departments.forEach((dept) => listener(dept));

    listeners.push(listener);

    const unsubscribe = () => {
      listeners.splice(listeners.indexOf(listener), 1);
    };

    return unsubscribe;
  };
})();

export function useZendeskDepartmentStatus() {
  const deptName = useZendeskDepartmentName();
  const [deptStatuses, setDeptStatuses] = useState<Record<string, ZendeskDepartment["status"]>>();

  useEffect(() => {
    const unsubscribe = subscribeToZendeskDepartmentStatus((dept) => {
      setDeptStatuses((prev) => ({ ...prev, [dept.name]: dept.status }));
    });

    return () => {
      unsubscribe();
    };
  }, [deptName]);

  return deptStatuses?.[deptName];
}

export function setZendeskWebWidgetVisibility(show: boolean) {
  if (show) {
    window.zE?.("webWidget", "show");
  } else {
    window.zE?.("webWidget", "hide");
  }
}

export function useHideZendeskWidget() {
  useLayoutEffect(() => {
    setZendeskWebWidgetVisibility(false);

    return () => {
      setZendeskWebWidgetVisibility(true);
    };
  }, []);
}

export function zendeskWebWidgetIsVisible() {
  const launcher = document.getElementById("launcher");
  const isVisible = launcher != null && launcher.style.visibility !== "hidden";
  return isVisible;
}

let identified = false;

export function identifyZendeskUser(name: string, email: string, phoneNumber: string | null) {
  if (!identified) {
    window.zE?.("webWidget", "identify", {
      name,
      email,
    });

    window.zE?.("webWidget", "prefill", {
      name: {
        value: name,
      },
      email: {
        value: email,
      },
      phone: {
        value: phoneNumber,
      },
    });

    identified = true;
  }
}

export function openZendeskChat() {
  // https://develop.zendesk.com/hc/en-us/articles/360002037047-Showing-and-hiding-the-Chat-window
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  window.$zopim.livechat.window.show();
}

export function useSetHelpCenterSuggestions(location: Location) {
  useEffect(() => {
    function matchAndSet(location: Location) {
      const matched = match(true, location.pathname) ?? match(false, location.pathname);
      if (matched) {
        const { zendeskLabels } = matched;
        setHelpCenterSuggestions(zendeskLabels);
      } else {
        resetHelpCenterSuggestions();
      }
    }

    matchAndSet(location);
  }, [location]);
}

function setHelpCenterSuggestions(labels: string[]) {
  window.zE?.("webWidget", "helpCenter:setSuggestions", {
    labels,
  });
}

function resetHelpCenterSuggestions() {
  window.zE?.("webWidget", "helpCenter:setSuggestions", {
    url: true,
  });
}

export function useSetZendeskEmployerNameField(
  id: string | null | undefined,
  ticketId: string | null | undefined,
  employerName: string | null | undefined,
) {
  useEffect(() => {
    if (id && ticketId && employerName) {
      window.zE?.("webWidget", "chat:addTags", [id, ticketId, employerName]);
    }
  }, [id, ticketId, employerName]);
}

export function setZendeskWidgetOffset(args: { horizontal?: string; vertical?: string }) {
  const { horizontal, vertical } = args;
  window.zE?.("webWidget", "updateSettings", {
    webWidget: {
      offset: { horizontal, vertical },
    },
  });
}

export function useListenToZendeskVisibility() {
  const [zendeskIsOpen, setZendeskIsOpen] = useState(false);

  useEffect(() => {
    window.zE?.("webWidget:on", "open", function () {
      setZendeskIsOpen(true);
    });

    window.zE?.("webWidget:on", "close", function () {
      setZendeskIsOpen(false);
    });
  }, [setZendeskIsOpen]);

  return zendeskIsOpen;
}
