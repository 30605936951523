import { browserTracingIntegration, init, withScope } from "@sentry/react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- used in tsdocs
import { handlePreloadErrors } from "client/src/utils/preloadError";
import { useEffect } from "react";

import { DSN } from "shared/data/Sentry";
import { isLocalDev, isTest } from "shared/utils/config";
import { assertIsDefined, getHostEnvironment } from "shared/utils/utils";
import type { User, setUser } from "@sentry/react";
import type { Env, InjectedEnv } from "shared/types/Env";

export function initSentry(): void {
  if (isLocalDev || isTest) {
    console.log("Sentry is disabled in local development.");
    return;
  }

  const hostEnvironment = getHostEnvironment(window.location.host);
  const environment: Env | "undetermined" = (
    {
      local: "local",
      qa: "qa-dcs",
      stage: "stage-dcs",
      production: "prod-dcs",
      unknown: "undetermined",
    } as const
  )[hostEnvironment];

  const commitHashMetaTag = document.querySelector<HTMLMetaElement>('meta[name="COMMIT_HASH"]');
  assertIsDefined(commitHashMetaTag, "commitHashMetaTag");
  const release =
    commitHashMetaTag.content === "%COMMIT_HASH%" ? "none" : commitHashMetaTag.content;

  init({
    dsn: DSN,
    tunnel: "/api/sentry",
    release,
    environment,
    integrations: [browserTracingIntegration()],
    normalizeDepth: 6,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: (
      {
        local: 1.0,
        qa: 1.0,
        stage: 1.0,
        production: 0.1,
        unknown: 0.0,
      } as const
    )[hostEnvironment],
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", window.location.host],

    ignoreErrors: [
      /**
       * Ignore errors that are caused by dynamically imported modules that fail to fetch
       * when they are deleted from the hosting server after a newer deployment has gone out.
       *
       * This is a common issue with code splitting and we can ignore them because we
       * immediately hard-refresh the page when this error is triggered.
       *
       * @see {@link handlePreloadErrors}
       */
      /Failed to fetch dynamically imported module/, // Chrome error message
      /error loading dynamically imported module/, // Firefox error message
      /Unable to preload CSS for/,
    ],
  });
}

export function useConfigureSentryScope(config: InjectedEnv | undefined) {
  useEffect(() => {
    if (config) {
      withScope((scope) => {
        scope.addEventProcessor(function (event) {
          event.release = config.COMMIT_HASH;
          event.environment = config.ENV;
          return event;
        });
      });
    }
  }, [config]);
}

let existingSentryUser: User | null = null;

export const setSentryUser: typeof setUser = (user) => {
  const isDifferent = JSON.stringify(existingSentryUser) !== JSON.stringify(user);
  if (isDifferent) {
    existingSentryUser = user;
    withScope((scope) => {
      scope.setUser(user);
    });
  }
};
