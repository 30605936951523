import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import isSameOrAfterPlugin from "dayjs/plugin/isSameOrAfter";
import { DateTime } from "luxon";

dayjs.extend(isBetweenPlugin);
dayjs.extend(isSameOrAfterPlugin);

const timezoneAdjustedDayjs = (d: dayjs.Dayjs) => d.subtract(d.utcOffset(), "minute");

export const timezoneAdjustedDate = (date: Date) => timezoneAdjustedDayjs(dayjs(date)).toDate();

export const isBefore = (date: Date) => dayjs().isBefore(timezoneAdjustedDate(date));

export const isAfter = (date: Date) => dayjs().isAfter(timezoneAdjustedDate(date));

export const isSameOrAfter = (date: Date) => dayjs().isSameOrAfter(timezoneAdjustedDate(date));

export const isBetween = (startDate: Date, endDate: Date) =>
  dayjs().isBetween(
    timezoneAdjustedDate(startDate),
    timezoneAdjustedDate(addDays(endDate, 1)),
    undefined,
    "[)",
  );

export const daysUntil = (date: Date) =>
  timezoneAdjustedDayjs(dayjs(date)).diff(dayjs().startOf("day"), "days");

export const addDays = (date: Date, days: number) => dayjs(date).add(days, "day").toDate();

export const buildDate = (policyEffective: Date) => {
  const luxonInstance = DateTime.fromJSDate(policyEffective, { zone: "UTC" });

  const month = luxonInstance.monthLong;
  const day = luxonInstance.day;
  const startOfDate = DateTime.utc().startOf("day");
  const daysDiffString = luxonInstance.toRelativeCalendar({
    unit: "days",
    base: startOfDate,
  });
  return { month, day, daysDiffString };
};

export const isPeakSeason = () => {
  const now = DateTime.local();
  const peakSeasonStart = DateTime.fromObject({
    day: 20,
    month: 11,
    year: now.month <= 2 ? now.year - 1 : now.year,
  });
  const peakSeasonEnd = DateTime.fromObject({
    day: 31,
    month: 1,
    year: now.month >= 11 ? now.year + 1 : now.year,
  });

  return (
    now.startOf("day") >= peakSeasonStart.startOf("day") &&
    now.endOf("day") <= peakSeasonEnd.endOf("day")
  );
};
