export function swapKeyValues<
  K extends string | number | symbol,
  V extends string | number | symbol,
>(record: Record<K, V>): Record<V, K> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const swappedRecord = Object.fromEntries(
    Object.entries(record).map(([key, value]) => [value, key]),
  ) as Record<V, K>;

  return swappedRecord;
}
