import { ExplorerPageSortFields, Languages, SortDirections } from "shared/types/ExplorerPage";
import { checkAlphaNumericHyphen } from "shared/utils/regex";
import { colorSchema } from "shared/validation/color";
import { explorerPageBenefitInputValidation } from "shared/validation/explorerPageBenefit";
import { locationStateCodeValidation, territoryValidation } from "shared/validation/location";
import * as Yup from "yup";

import type {
  ExplorerPageSortField,
  SortDirection,
  Language,
  MedicalVideoUploadSection,
  ExplorerBenefitTypeMetadata,
} from "shared/types/ExplorerPage";
import "shared/validation/email";

const benefitMetadataSchema = Yup.object({
  highlighterText: Yup.string().trim().optional().nullable(),
  spanishHighlighterText: Yup.string().trim().optional().nullable(),
  highlighterOrder: Yup.number().optional(),
})
  .noUnknown()
  .default(undefined);

const benefitTypeMetadataValidation: Yup.ObjectSchema<ExplorerBenefitTypeMetadata> = Yup.object({
  MEDICAL: benefitMetadataSchema,
  ACCIDENT: benefitMetadataSchema,
  CRITICAL_ILLNESS: benefitMetadataSchema,
  HOSPITAL_INDEMNITY: benefitMetadataSchema,
  CANCER: benefitMetadataSchema,
  GAP: benefitMetadataSchema,
  DENTAL: benefitMetadataSchema,
  VISION: benefitMetadataSchema,
  SHORT_TERM_DISABILITY: benefitMetadataSchema,
  LONG_TERM_DISABILITY: benefitMetadataSchema,
  LIFE: benefitMetadataSchema,
  FINANCIAL: benefitMetadataSchema,
  OTHER: benefitMetadataSchema,
});

const medicalVideoUploadSectionSchema =
  Yup.mixed<MedicalVideoUploadSection>().oneOf<MedicalVideoUploadSection>(
    ["NO_VIDEO", "ENGLISH_VIDEO", "ENGLISH_AND_SPANISH_VIDEO"],
    "Please provide a response.",
  );

export const explorerPageInputValidation = Yup.object({
  name: Yup.string().trim().required("Please enter a site name."),
  benefits: Yup.array().of(explorerPageBenefitInputValidation).nullable(),
  kioskMode: Yup.boolean().required(),
  startsAt: Yup.date().required(),
  expiresAt: Yup.date().optional().nullable(),
  planYear: Yup.string()
    .nullable()
    .test({
      name: "format",
      message: "Please enter a numerical value for the plan year(s). Example: #### or ####-####",
      test: (val) => {
        const regex = new RegExp("^[0-9.-]*$");
        if (val) return regex.test(val);
        return false;
      },
    }),
  openEnrollmentStart: Yup.date().required(),
  openEnrollmentEnd: Yup.date().required(),
  maintenanceMode: Yup.boolean().required(),
  benefitTypeMetadata: benefitTypeMetadataValidation.nullable(), //.default(undefined),
  employerState: locationStateCodeValidation.required("Employer state is required"),
  benAdminPlatformId: Yup.number().optional().nullable(),
  benAdminPlatformUrl: Yup.string().optional().nullable(),
  notificationLink: Yup.string().optional().nullable(),
  customCalendlyLink: Yup.string().optional().nullable(),
  customClientName: Yup.string().optional().nullable(),
  logoDocumentId: Yup.string().optional().nullable(),
  employeeCount: Yup.number().integer().min(0).optional().nullable(),
  hasEmployeesInNY: Yup.boolean().required(),
  showBenefitCount: Yup.boolean().required(),
  associationId: Yup.string(),
  hideSunLifeLogo: Yup.boolean().required(),
  pageOwner: Yup.string().required(),
  policies: Yup.array().of(Yup.string().trim().required()).min(1),
  medicalVideo: medicalVideoUploadSectionSchema.nullable(),
  medicalVideoEnglishTitle: Yup.string()
    .nullable()
    .when("medicalVideo", {
      is: "ENGLISH_VIDEO",
      then: (schema) => schema.trim().nullable().required(),
      otherwise: (schema) => schema.trim().optional().nullable(),
    }),
  medicalVideoSpanishTitle: Yup.string()
    .nullable()
    .when("medicalVideo", {
      is: "ENGLISH_VIDEO",
      then: (schema) => schema.trim().nullable(),
      otherwise: (schema) => schema.trim().optional().nullable(),
    }),
  medicalVideoDocumentId: Yup.string()
    .nullable()
    .when("medicalVideo", {
      is: "ENGLISH_VIDEO",
      then: (schema) => schema.trim().nullable().required(),
      otherwise: (schema) => schema.trim().optional().nullable(),
    }),
  vidyardUploadId: Yup.string().optional().nullable(),
  vidyardId: Yup.number().optional().nullable(),
  territory: territoryValidation.optional().nullable(),
  showLegacyVisionPlanTable: Yup.boolean().optional().default(false),
  showLegacyDentalPlanTable: Yup.boolean().optional().default(false),
  vanitySlug: Yup.string()
    .trim()
    .optional()
    .nullable()
    .test({
      name: "format",
      message: "Please input only alphanumeric characters",
      test: (val) => checkAlphaNumericHyphen(val),
    }),
  customBranding: Yup.boolean().optional().default(false),
  customThemeBrandColor: colorSchema.nullable().when("customBranding", {
    is: true,
    then: (schema) => schema.required("Brand color is required"),
  }),
  customThemeContrastingColor: colorSchema.nullable().when("customBranding", {
    is: true,
    then: (schema) => schema.required("Contrast color is required"),
  }),
});

const explorerPageSortByValidator = Yup.mixed<ExplorerPageSortField>().oneOf<ExplorerPageSortField>(
  [...ExplorerPageSortFields],
);

export const sortDirectionValidator = Yup.mixed<SortDirection>().oneOf<SortDirection>([
  ...SortDirections,
]);

export const languageValidator = Yup.mixed<Language>().oneOf<Language>([...Languages]);

export const getExplorerPageValidation = {
  params: Yup.object({ clientId: Yup.string().required() }).required(),
  query: Yup.object({
    limit: Yup.number().max(100).min(1),
    offset: Yup.number().min(0),
    search: Yup.string(),
    sortBy: explorerPageSortByValidator,
    sortDirection: sortDirectionValidator,
  }),
};

export const getExplorerPageByIdValidation = {
  params: Yup.object({ explorerPageId: Yup.string().required() }).required(),
};

export const explorerPagePrepaidLocationValidation = Yup.object({
  prepaidLocation: locationStateCodeValidation.required("State is required"),
});

export const explorerPageLanguageModalValidation = Yup.object({
  language: languageValidator.required("Language is required"),
});

export const sendPersonalBenefitsPlanRequestValidation = Yup.object({
  email: Yup.string().emailV1().required("Email is required"),
  benefits: Yup.array()
    .of(Yup.string().required())
    .min(1, "Need at least one selected benefit.")
    .required(),
  recaptchaToken: Yup.string().required("Recaptcha token is missing"),
  language: languageValidator.required("Language is required"),
});

export const leadGenerationRequestValidation = Yup.object({
  email: Yup.string().emailV1().required("Email is required"),
  firstName: Yup.string().trim().required("First name is required"),
  lastName: Yup.string().trim().required("Last name is required"),
  city: Yup.string().trim().required("City is required"),
  state: locationStateCodeValidation.required("State is required"),
  phone: Yup.string().trim().required("Phone is required"),
  externalUser: Yup.string().trim().required("Employer/Broker is required"),
  employerName: Yup.string().trim().required("Employer name is required"),
  brokerName: Yup.string()
    .nullable()
    .when("externalUser", {
      is: "broker",
      then: (schema) => schema.required("Broker name is required"),
      otherwise: (schema) => schema.optional(),
    }),
});
