import { getIsLocationStateCode, type LocationStateCode } from "shared/types/Location";

export const LocationStateData: Record<LocationStateCode, { displayName: string }> = {
  AK: { displayName: "Alaska" },
  AL: { displayName: "Alabama" },
  AR: { displayName: "Arkansas" },
  AZ: { displayName: "Arizona" },
  CA: { displayName: "California" },
  CO: { displayName: "Colorado" },
  CT: { displayName: "Connecticut" },
  DC: { displayName: "District of Columbia" },
  DE: { displayName: "Delaware" },
  FL: { displayName: "Florida" },
  GA: { displayName: "Georgia" },
  HI: { displayName: "Hawaii" },
  IA: { displayName: "Iowa" },
  ID: { displayName: "Idaho" },
  IL: { displayName: "Illinois" },
  IN: { displayName: "Indiana" },
  KS: { displayName: "Kansas" },
  KY: { displayName: "Kentucky" },
  LA: { displayName: "Louisiana" },
  MA: { displayName: "Massachusetts" },
  MD: { displayName: "Maryland" },
  ME: { displayName: "Maine" },
  MI: { displayName: "Michigan" },
  MN: { displayName: "Minnesota" },
  MO: { displayName: "Missouri" },
  MS: { displayName: "Mississippi" },
  MT: { displayName: "Montana" },
  NC: { displayName: "North Carolina" },
  ND: { displayName: "North Dakota" },
  NE: { displayName: "Nebraska" },
  NH: { displayName: "New Hampshire" },
  NJ: { displayName: "New Jersey" },
  NM: { displayName: "New Mexico" },
  NV: { displayName: "Nevada" },
  NY: { displayName: "New York" },
  OH: { displayName: "Ohio" },
  OK: { displayName: "Oklahoma" },
  OR: { displayName: "Oregon" },
  PA: { displayName: "Pennsylvania" },
  RI: { displayName: "Rhode Island" },
  SC: { displayName: "South Carolina" },
  SD: { displayName: "South Dakota" },
  TN: { displayName: "Tennessee" },
  TX: { displayName: "Texas" },
  UT: { displayName: "Utah" },
  VA: { displayName: "Virginia" },
  VT: { displayName: "Vermont" },
  WA: { displayName: "Washington" },
  WI: { displayName: "Wisconsin" },
  WV: { displayName: "West Virginia" },
  WY: { displayName: "Wyoming" },
};

const displayNameToLocationStateCode: Record<string, LocationStateCode> = Object.entries(
  LocationStateData,
).reduce((acc, [code, { displayName }]) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  acc[displayName] = code as LocationStateCode;
  return acc;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
}, {} as Record<string, LocationStateCode>);

export const getLocationStateCode = (searchValue: string): LocationStateCode | undefined => {
  return getIsLocationStateCode(searchValue)
    ? searchValue
    : displayNameToLocationStateCode[searchValue];
};
