import {
  accessController,
  BEN_ADMIN,
  BROKER,
  CLIENT,
  CLIENT_ACTION,
  CLIENT_DOCUMENT,
  CLIENT_ONBOARDING_FORMS,
  CLIENT_WEBLINK,
  SLF_OWNER,
  SLF_ADMIN,
} from "shared/rbac/roles";
import { isPromise } from "shared/utils/utils";

import type { Permission } from "accesscontrol";
import type { ResourceType, UserRole } from "shared/rbac/roles";
import type { ClientId } from "shared/types/Client";
import type { AsyncFn, ValueOf } from "shared/types/Helper";

const supportedMethods = ["read", "update", "create", "delete"] as const;
export type SupportedMethod = ValueOf<typeof supportedMethods>;

export type HasAccessToResourceSync = (
  resource: ResourceType,
  userData: UserData,
  resourceId: string,
  hasAnyLevelPermission: boolean,
  method: SupportedMethod,
) => boolean;
export type HasAccessToResource = AsyncFn<HasAccessToResourceSync>;

const isSupportedMethod = (method: string): method is SupportedMethod => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return supportedMethods.includes(method as SupportedMethod);
};

export type UserData = {
  role: UserRole;
  clientIds: ClientId[] | null;
  userId: string;
  email: string;
  name: string;
  termsOfUseDate?: Date | null;
  phoneNumber: string | null;
  id?: string | null;
  hasMfa: boolean;
};

export type RbacCheckTypeSync = (
  method: SupportedMethod,
  resource: ResourceType,
  resourceId?: string,
) => Permission | { granted: false };
export type RbacCheckType = AsyncFn<RbacCheckTypeSync>;

export const checkPermissions = (
  role: UserRole,
  resource: ResourceType,
  type: string,
  level: "own" | "any",
) => {
  const permission = accessController.permission({
    role: [role],
    action: `${type}:${level}`,
    resource,
  });

  return permission;
};

export const defaultHasAccessToResource: HasAccessToResource = (
  resource,
  { clientIds, userId },
  resourceId,
): boolean => {
  switch (resource) {
    case CLIENT:
    case CLIENT_DOCUMENT:
    case CLIENT_WEBLINK:
    case CLIENT_ACTION:
    case CLIENT_ONBOARDING_FORMS: {
      if (!clientIds) {
        throw new Error(`User does not have any client IDs attached to profile`);
      }
      const hasAccess = clientIds.map((c) => c.toString()).includes(resourceId);
      return hasAccess;
    }
    case BROKER: {
      if (!userId) {
        throw new Error(`User missing valid user ID`);
      }
      return userId === resourceId;
    }
    case "SLF_OWNER":
    case "SLF_ADMIN":
    case "BEN_ADMIN":
    case "client-eif":
    case "email":
    case "purge-qa-data":
    case "ben-admin-assign":
    case "broker-assign":
    case "onboarding-form-reassign":
    case "trigger-post-onboarding-survey":
    case "trigger-phase-change-emails":
    case "trigger-risk-acceptance-emails":
    case "generate-emails_activities":
    case "trigger-dental-emails":
    case "generate-emails-upcoming-dates":
    case "generate-emails-actively-at-work-reminder":
    case "resend-rejected-emails":
    case "carrier":
    case "get-emails":
    case "resend-emails":
    case "get-ben-admins":
    case "get-brokers":
    case "get-slf-admins":
    case "get-slf-owners":
    case "get-slf-users":
    case "get-users":
    case "copy-activation-link":
    case "bulk-update-email-and-send-activation-email":
    case "update-phase-change-status-flag":
    case "undo-ic-edit":
    default: {
      throw new Error(`No 'own' resource defined for resource type ${resource}`);
    }
  }
};

export const defaultPermissionCheck = (
  hasAccessToResource: boolean,
  hasPermission: boolean,
): boolean => {
  return hasAccessToResource && hasPermission;
};

type PermissionCheckSync = (hasAccessToResource: boolean, hasPermission: boolean) => boolean;
type PermissionCheck = AsyncFn<PermissionCheckSync>;

// Sync and Async overloads
export function buildRBAC(
  user: UserData | null,
  hasAccessToResource?: HasAccessToResourceSync,
  permissionCheck?: PermissionCheckSync,
): RbacCheckTypeSync;
export function buildRBAC(
  user: UserData | null,
  hasAccessToResource?: HasAccessToResource,
  permissionCheck?: PermissionCheck,
): RbacCheckType;
export function buildRBAC(
  user: UserData | null,
  hasAccessToResource: HasAccessToResourceSync | HasAccessToResource = defaultHasAccessToResource,
  permissionCheck: PermissionCheckSync | PermissionCheck = defaultPermissionCheck,
): RbacCheckTypeSync | RbacCheckType {
  if (!user) {
    return () => ({ granted: false });
  }

  const role = user.role;

  // Admin users can work on 'any' resource. Non-admins can only work on 'own' resources.
  const level = role === SLF_OWNER || role === SLF_ADMIN ? "any" : "own";

  return (method: SupportedMethod, resource: ResourceType, resourceId?: string) => {
    if (!isSupportedMethod(method)) {
      throw new Error(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- this is just to be supersafe
        `Request Method ${method as string} not supported for Role Based Access Control`,
      );
    }

    const permission = checkPermissions(role, resource, method, level);
    if ((role === SLF_OWNER || role === SLF_ADMIN) && permission.granted) {
      return permission;
    }

    // For the "create" action we don't need to check the resource ID
    if (method === "create" || !resourceId) {
      return permission;
    }

    const hasAnyLevelPermission = checkPermissions(role, resource, method, "any").granted;

    const accessToResources = hasAccessToResource(
      resource,
      user,
      resourceId,
      hasAnyLevelPermission,
      method,
    );

    // can't use async/await because we need to keep the return type synchronous in case `accessToResources` is sync
    if (isPromise(accessToResources)) {
      return accessToResources
        .then((accessToResources) => permissionCheck(accessToResources, permission.granted))
        .then((check) => (check ? permission : { granted: false }));
    } else {
      const check = permissionCheck(accessToResources, permission.granted);
      return check ? permission : { granted: false };
    }
  };
}

export function getIsOwnerUser(userData: Pick<UserData, "role"> | null): boolean {
  return Boolean(userData?.role === SLF_OWNER);
}

export function getIsSlfAdmin(userData: Pick<UserData, "role"> | null): boolean {
  return Boolean(userData?.role === SLF_ADMIN);
}

export function getIsInternalUser(userData: Pick<UserData, "role"> | null): boolean {
  return Boolean(userData?.role === SLF_OWNER || userData?.role === SLF_ADMIN);
}

export function getIsBroker(
  userData: Pick<UserData, "role"> | null,
): userData is { role: typeof BROKER } {
  return Boolean(userData?.role === BROKER);
}

export function getIsBenAdmin(
  userData: Pick<UserData, "role"> | null,
): userData is { role: typeof BEN_ADMIN } {
  return Boolean(userData?.role === BEN_ADMIN);
}
