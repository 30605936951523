import { message } from "antd";

import { Spinner } from "client/src/components/Spinner/Spinner";
import CircleComplete from "../Icons/CircleComplete.svg?react";

import * as styles from "./slobMessage.module.less";

import type { ArgsProps } from "antd/lib/message";
import type { MessageInstance } from "antd/lib/message/interface";

type NoticeType = "info" | "success" | "error" | "warning" | "loading";

type ConfigContent = React.ReactNode | string;
type JointContent = ConfigContent | ArgsProps;
type ConfigDuration = number | (() => void);

const getIcon = (type: NoticeType) => {
  switch (type) {
    case "success":
      return (
        <span role="img" className={styles.slobMessageIcon} aria-label="Success">
          <CircleComplete />
        </span>
      );
    case "loading":
      return (
        <span role="img" className={styles.slobMessageIcon} aria-label="Loading">
          <Spinner size="xsmall" />
        </span>
      );
    case "error":
    case "warning":
    case "info":
    default:
      return undefined;
  }
};

const attachTypeApi = (
  type: NoticeType,
  message: MessageInstance,
  content: JointContent,
  duration?: ConfigDuration,
  onClose?: VoidFunction,
) => {
  const icon = getIcon(type);
  if (isArgsProps(content)) {
    return message.open({
      ...content,
      type,
      className: styles.slobMessageStyles,
      icon,
    });
  }

  if (typeof duration === "function") {
    onClose = duration;
    duration = undefined;
  }

  return message.open({
    type,
    content,
    duration,
    onClose,
    className: styles.slobMessageStyles,
    icon,
  });
};

export const slobMessage: typeof message = {
  ...message,
  info: (...args) => attachTypeApi("info", message, ...args),
  success: (...args) => attachTypeApi("success", message, ...args),
  error: (...args) => attachTypeApi("error", message, ...args),
  warning: (...args) => attachTypeApi("warning", message, ...args),
  loading: (...args) => attachTypeApi("loading", message, ...args),
  open: (args) => attachTypeApi(args.type || "info", message, args),

  useMessage: () => {
    const [api, contextHolder] = message.useMessage();

    return [
      {
        info: (...args) => attachTypeApi("info", api, ...args),
        success: (...args) => attachTypeApi("success", api, ...args),
        error: (...args) => attachTypeApi("error", api, ...args),
        warning: (...args) => attachTypeApi("warning", api, ...args),
        loading: (...args) => attachTypeApi("loading", api, ...args),
        open: (args) => attachTypeApi(args.type || "info", api, args),
        destroy: message.destroy,
      },
      contextHolder,
    ];
  },
};

function isArgsProps(content: JointContent): content is ArgsProps {
  return (
    Object.prototype.toString.call(content) === "[object Object]" &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !!content?.content
  );
}
