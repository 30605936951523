import { capitalize } from "client/src/utils/string";
import clsx from "clsx";
import React, { createContext, useContext } from "react";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import * as styles from "./grid.module.less";

interface RowContextState {
  gutter?: [number, number | undefined];
  wrap?: boolean;
}

const RowContext = createContext<RowContextState>({});

type RowAlign = "top" | "middle" | "bottom" | "stretch";
type RowJustify = "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly";

type Gutter = number | [number, number] | undefined;

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  gutter?: Gutter;
  align?: RowAlign;
  justify?: RowJustify;
  wrap?: boolean;
}

const getJustifyClass = (justify: RowJustify | undefined) => {
  switch (justify) {
    case undefined:
      return null;
    case "start":
      return styles.justifyStart;
    case "end":
      return styles.justifyEnd;
    case "center":
      return styles.justifyCenter;
    case "space-around":
      return styles.justifySpaceAround;
    case "space-between":
      return styles.justifySpaceBetween;
    case "space-evenly":
      return styles.justifySpaceEvenly;
    default:
      exhaustiveCheck(justify);
      break;
  }
};

const getAlignClass = (align: RowAlign | undefined) => {
  switch (align) {
    case undefined:
      return null;
    case "top":
      return styles.alignTop;
    case "middle":
      return styles.alignMiddle;
    case "bottom":
      return styles.alignBottom;
    case "stretch":
      return null;
    default:
      exhaustiveCheck(align);
      break;
  }
};

export const Row = React.forwardRef<HTMLDivElement, RowProps>((props, ref) => {
  const { justify, align, className, style, children, gutter = 0, wrap, ...others } = props;

  const getGutter = () => {
    return Array.isArray(gutter) ? gutter : ([gutter, undefined] as const);
  };

  const classes = clsx(
    styles.row,
    getJustifyClass(justify),
    getAlignClass(align),
    {
      [styles.noWrap]: wrap === false,
    },
    className,
  );

  const gutters = getGutter();

  const rowStyle: React.CSSProperties = {};
  const horizontalGutter = gutters[0] > 0 ? gutters[0] / -2 : undefined;

  if (horizontalGutter) {
    rowStyle.marginLeft = horizontalGutter;
    rowStyle.marginRight = horizontalGutter;
  }

  [, rowStyle.rowGap] = gutters;

  const [gutterH, gutterV] = gutters;

  const rowContext = React.useMemo<RowContextState>(
    () => ({ gutter: [gutterH, gutterV], wrap }),
    [gutterH, gutterV, wrap],
  );

  return (
    <RowContext.Provider value={rowContext}>
      <div {...others} className={classes} style={{ ...rowStyle, ...style }} ref={ref}>
        {children}
      </div>
    </RowContext.Provider>
  );
});

function parseFlex(flex: number | string): string {
  if (typeof flex === "number") {
    return `${flex} ${flex} auto`;
  }

  if (/^\d+(\.\d+)?(px|em|rem|%)$/.test(flex)) {
    return `0 0 ${flex}`;
  }

  return flex;
}

const sizes = ["xs", "sm", "md", "lg", "xl", "xxl"] as const;
type CapitalizedSize = "Xs" | "Sm" | "Md" | "Lg" | "Xl" | "Xxl";

type ColSpanType = number | string;

type ValidColType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24;

export interface ColSize {
  span?: number;
  offset?: number;
}

export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
  flex?: number | string;
  xs?: ColSpanType | ColSize;
  sm?: ColSpanType | ColSize;
  md?: ColSpanType | ColSize;
  lg?: ColSpanType | ColSize;
  xl?: ColSpanType | ColSize;
  xxl?: ColSpanType | ColSize;
  className?: string;
  offset?: ColSpanType;
  span?: ColSpanType;
  key?: string;
}

const isValidColumnValue = (num: number | undefined) => Number(num) >= 0 && Number(num) <= 24;

export const Col = React.forwardRef<HTMLDivElement, ColProps>((props, ref) => {
  const { style, flex, span, offset, xs, sm, md, lg, xl, xxl, className = "", ...rest } = props;

  const { gutter, wrap } = useContext(RowContext);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const validSpan = Number(span) as ValidColType;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const validOffset = Number(offset) as ValidColType;

  const mergedStyle: React.CSSProperties = {};
  if (gutter && gutter[0] > 0) {
    const horizontalGutter = gutter[0] / 2;
    mergedStyle.paddingLeft = horizontalGutter;
    mergedStyle.paddingRight = horizontalGutter;
  }

  if (flex) {
    mergedStyle.flex = parseFlex(flex);

    // Hack for Firefox to avoid size issue
    // https://github.com/ant-design/ant-design/pull/20023#issuecomment-564389553
    if (wrap === false && !mergedStyle.minWidth) {
      mergedStyle.minWidth = 0;
    }
  }

  let sizeClassObj = {};
  sizes.forEach((size) => {
    if (!props[size]) return;
    let sizeProps: ColSize = {};
    const propSize = props[size];
    if (typeof propSize === "number") {
      sizeProps.span = propSize;
    } else if (typeof propSize === "object") {
      sizeProps = propSize;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const capitalizedSize = capitalize(size) as CapitalizedSize;

    const sizeSpan = isValidColumnValue(sizeProps.span)
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (sizeProps.span as ValidColType)
      : undefined;

    const sizeOffset = isValidColumnValue(sizeProps.offset)
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (sizeProps.offset as ValidColType)
      : undefined;

    sizeClassObj = {
      ...sizeClassObj,
      ...(sizeSpan ? { [styles[`sizeSpan${capitalizedSize}${sizeSpan}`]]: true } : {}),
      ...(sizeOffset ? { [styles[`sizeOffset${capitalizedSize}${sizeOffset}`]]: true } : {}),
    };
  });

  const classes = clsx(
    styles.col,
    {
      // general, not size related
      ...(validSpan && isValidColumnValue(validSpan)
        ? { [styles[`generalSpan${validSpan}`]]: true }
        : {}),
      ...(validOffset && isValidColumnValue(validOffset)
        ? { [styles[`generalOffset${validOffset}`]]: true }
        : {}),
    },
    className,
    sizeClassObj,
  );

  return <div {...rest} style={{ ...mergedStyle, ...style }} className={classes} ref={ref} />;
});
