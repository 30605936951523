import * as Popover from "@radix-ui/react-popover";
import { H3 } from "client/src/components/Typography/Typography";
import clsx from "clsx";
import { useCallback, useImperativeHandle, useRef, useState } from "react";

import * as styles from "./slobPopover.module.less";
import type React from "react";

import type { ComponentProps } from "react";

type Side = ComponentProps<typeof Popover.Content>["side"];

type SlobPopoverRef = { setOpen: (open: boolean) => void };

type Props = {
  variant?: "primary" | "secondary";
  title?: string;
  content: React.ReactNode;
  children: React.ReactElement;
  side?: Side;
  popoverRef?: React.RefObject<SlobPopoverRef | null>;
  compact?: boolean;
};

export function SlobPopover(props: Props) {
  const { variant = "primary", title, children, content, side, popoverRef, compact } = props;

  const [open, setOpen] = useState(false);

  useImperativeHandle(popoverRef, () => ({ setOpen }));

  return (
    <Popover.Root open={open} onOpenChange={content ? setOpen : undefined}>
      <Popover.Trigger asChild>{children}</Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className={clsx(
            styles.PopoverContent,
            variant === "secondary" && styles.Popover__secondary,
            compact && styles.Popover__compact,
          )}
          sideOffset={5}
          side={side}
        >
          {title && (
            <H3 as="div" white={variant === "primary"}>
              {title}
            </H3>
          )}

          {content}

          <Popover.Arrow className={styles.PopoverArrow} aria-hidden />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export function usePopoverRef() {
  const popoverRef = useRef<SlobPopoverRef>(null);
  const setOpen = useCallback((open: boolean) => popoverRef.current?.setOpen(open), []);
  return [popoverRef, setOpen] as const;
}
