import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body5 } from "client/src/components/Typography/Typography";
import { getIsEmptySpan } from "client/src/utils/getIsEmptySpan";
import clsx from "clsx";
import { useState, useCallback, forwardRef, useId } from "react";

import * as styles from "./FormInput.module.less";
import * as labelStyles from "./form-labels.module.less";

import type { InputRef } from "antd";
import type { CommonFormInputsProps } from "client/src/components/Form/formTypes";
import type { AriaRole, HTMLInputTypeAttribute, KeyboardEventHandler } from "react";

export type FormInputProps = CommonFormInputsProps & {
  value: string | number | readonly string[] | null | undefined;
  maxLength: number;
  isPassword?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  topText?: React.ReactNode;
  bottomText?: React.ReactNode;
  allowClear?: boolean;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  autoComplete?: string;
  type?: HTMLInputTypeAttribute;
  role?: AriaRole | undefined;
  "aria-describedby"?: string;
};

export const FormInput = forwardRef<InputRef, FormInputProps>(
  (
    {
      label,
      name,
      value,
      error,
      onChange,
      onBlur,
      onFocus,
      maxLength,
      disabled = false,
      touched = false,
      isPassword = false,
      showRequired,
      suffix,
      prefix,
      topText,
      bottomText,
      allowClear,
      readOnly,
      "aria-describedby": ariaDescribedBy,
      ...rest
    }: FormInputProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const isPrefixEmpty = getIsEmptySpan(prefix) || !prefix;
    const isSuffixEmpty = getIsEmptySpan(suffix) || !suffix;
    const isSuffixIcon = !isSuffixEmpty && !!suffix && typeof suffix !== "string";
    const shouldShowError = error && touched;
    const shouldUseWrapper = !isPrefixEmpty || !isSuffixEmpty || isPassword;

    const hasValue = value != null && value !== "";

    const labelClass = clsx(
      labelStyles.formLabel,
      { [labelStyles.formLabelOver]: hasValue || isFocused },
      { [labelStyles.formLabelDisabled]: disabled },
    );
    const containerClass = !shouldUseWrapper
      ? styles.fieldContainer
      : clsx(
          styles.wrapperFieldContainer,
          !readOnly && isFocused && !isPassword && styles.wrapperFieldContainer__focused,
          !isPrefixEmpty && styles.wrapperFieldContainer__prefixed,
          !isSuffixEmpty && !isPassword && styles.wrapperFieldContainer__suffixed,
          hasValue && !isPassword && styles.wrapperFieldContainer__filled,
          readOnly && styles.wrapperFieldContainer__readOnly,
          isSuffixIcon && styles.isSuffixIcon,
        );
    const inputClass = shouldUseWrapper
      ? shouldShowError
        ? styles.inputError
        : ""
      : clsx(styles.formInput, { [styles.inputError]: shouldShowError });

    const onInputFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (!readOnly) {
          setIsFocused(true);
          if (onFocus) {
            onFocus(e);
          }
        }
      },
      [readOnly, onFocus],
    );

    const onInputBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setIsFocused(false);
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur],
    );

    const id = useId();

    const errorId = shouldShowError ? `${id}__errormessage` : undefined;
    const topTextId = topText ? `${id}__top-description` : undefined;
    const bottomTextId = bottomText ? `${id}__bottom-description` : undefined;

    const props = {
      ...rest,
      id,
      name: name,
      value: value == null ? undefined : value,
      "aria-invalid": !!shouldShowError,
      "aria-describedby": [topTextId, bottomTextId, ariaDescribedBy].join(" ").trim(),
      "aria-errormessage": shouldShowError ? errorId : undefined,
      onChange: onChange,
      maxLength: maxLength,
      onFocus: onInputFocus,
      onBlur: onInputBlur,
      disabled: disabled,
      className: inputClass,
      suffix: suffix,
      prefix: prefix,
      allowClear: allowClear,
      readOnly: readOnly,
    };

    const passwordIconRender = useCallback(
      (visibility: boolean) => (
        <span>
          <button
            type="button"
            className="btn-reset"
            aria-label={visibility ? "Hide password" : "Show password"}
          >
            {visibility ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </button>
        </span>
      ),
      [],
    );

    const input = !isPassword ? (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - this is allowed
      <Input {...props} ref={ref} />
    ) : (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - this is allowed
      <Input.Password {...props} ref={ref} iconRender={passwordIconRender} />
    );

    return (
      <StackY dist={8} className={containerClass} wrap={false}>
        {topText &&
          (typeof topText === "string" ? (
            <Body2 id={topTextId} as="p">
              {topText}
            </Body2>
          ) : (
            <div id={topTextId} className="mb-16">
              {topText}
            </div>
          ))}

        <div>
          <label className={labelClass} htmlFor={id}>
            {label}
            {showRequired ? "*" : ""}
          </label>

          {input}
        </div>

        <div aria-live="assertive" className="hide:empty">
          {shouldShowError && <InputErrorMessage id={errorId} error={error} disabled={disabled} />}
        </div>

        {bottomText && (
          <Body5 id={bottomTextId} as="div">
            {bottomText}
          </Body5>
        )}
      </StackY>
    );
  },
);
