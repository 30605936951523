import { NavBar } from "client/src/components/NavBar/NavBar";
import { SlobNavLink } from "client/src/components/SlobLink/SlobLink";
import { StackX } from "client/src/components/Spacing/Spacing";
import { useSlobAuth } from "client/src/hooks/auth";

import { NavItemPopover } from "client/src/pages/Navigation/NavItemPopover";
import { UsersNavPopover } from "client/src/pages/Navigation/UsersNavPopover";
import { RouteData } from "shared/config/routeData";
import { SLF_OWNER } from "shared/rbac/roles";

import type { logOutType } from "client/src/pages/Navigation/NavBarPage";
import type { RbacCheckTypeSync } from "shared/rbac/rbac";

type InternalNavPageProps = {
  logOut: logOutType;
};

const hasAccess = (accessCheck: RbacCheckTypeSync) => {
  try {
    return accessCheck("update", SLF_OWNER).granted && accessCheck("delete", SLF_OWNER).granted;
  } catch (_error) {
    return false;
  }
};

const getCenterLinks = (accessCheck: RbacCheckTypeSync) => {
  const linksAccess = [
    {
      route: RouteData.allEmails,
      hasAccess: hasAccess(accessCheck),
    },
  ];
  return linksAccess.filter((link) => link.hasAccess).map((link) => link.route);
};

export const InternalNavPage = ({ logOut }: InternalNavPageProps) => {
  const { authUser, accessCheck } = useSlobAuth();

  const userName = authUser?.name || "";

  const centerLinks = getCenterLinks(accessCheck).map((link) => {
    return (
      <SlobNavLink key={link.friendlyName} to={link.getPath()}>
        {link.friendlyName}
      </SlobNavLink>
    );
  });

  const rightLinks = (
    <StackX dist={48}>
      <SlobNavLink to={RouteData.homeInternal.getPath()}>
        {RouteData.homeInternal.friendlyName}
      </SlobNavLink>

      <UsersNavPopover showUserInternalLink={hasAccess(accessCheck)} />

      {centerLinks}

      <NavItemPopover userName={userName} email={authUser?.email} logOut={logOut} />
    </StackX>
  );

  return <NavBar homeLink={RouteData.homeInternal.getPath()} rightLinks={rightLinks} />;
};
