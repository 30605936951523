import { isDEIFChangeSnapshotEntity } from "shared/types/Change";
import { eifSubStepIds } from "shared/types/EIF";
import { getChangeDetailInfoListForSubStep } from "shared/utils/EIF/getChangeDetailInfoList";
import { assertIsDefined, isEqual } from "shared/utils/utils";
import type {
  ChangeWithDetails,
  DEIFChangeSnapshot,
  ChangeDetailInfo,
  DEIFSnapshotEntity,
  ChangeDetailRecordWithMetadata,
  ChangeMetadata,
} from "shared/types/Change";
import type { Client } from "shared/types/Client";

const valueDidNotChange = (change: ChangeDetailInfo) => {
  return (
    isEqual(change.currentValue.value, change.previousValue.value) ||
    // false and null are the same for Boolean? checkboxes - see "should not interpret boolean false to null as a change" test case
    (change.currentValue.value === false && change.previousValue.value === null) ||
    (change.currentValue.value === null && change.previousValue.value === false)
  );
};

const getNetChangesForEntity = (
  entityChangesByAttribute: Record<string, ChangeDetailInfo[]>,
): Record<string, ChangeDetailInfo> => {
  const netChangesForEntity: Record<string, ChangeDetailInfo> = {};

  for (const attribute in entityChangesByAttribute) {
    const changesForAttribute = entityChangesByAttribute[attribute];
    assertIsDefined(changesForAttribute, "changesForAttribute");

    const netChange = getNetChangeForAttribute(changesForAttribute);
    if (netChange) {
      netChangesForEntity[attribute] = netChange;
    }
  }

  const createIsPending =
    netChangesForEntity.createdAt?.currentValue.value &&
    netChangesForEntity.createdAt.previousValue.value === null &&
    netChangesForEntity.createdAt.status === "pending";

  const createWasAccepted =
    netChangesForEntity.createdAt?.currentValue.value &&
    netChangesForEntity.createdAt.previousValue.value === null &&
    netChangesForEntity.createdAt.status === "accepted";

  const deleteIsPending =
    netChangesForEntity.deletedAt?.currentValue.value &&
    netChangesForEntity.deletedAt.previousValue.value === null &&
    netChangesForEntity.deletedAt.status === "pending";

  const deleteWasAccepted =
    netChangesForEntity.deletedAt?.currentValue.value &&
    netChangesForEntity.deletedAt.previousValue.value === null &&
    netChangesForEntity.deletedAt.status === "accepted";

  if (createIsPending && deleteIsPending) {
    // If an entity was created and then deleted during the most recent pending IC edit, there are no net relevant changes
    return {};
  } else if (createWasAccepted && deleteIsPending) {
    // fix for TB-7064
    // remove attribute changes that were previously accepted, leaving only the pending changes for the delete
    // this is so that the net change looks like deleting a entity created pre-signing (because accepting changes created a new baseline)
    const netChangesThatWereNotAccepted: Record<string, ChangeDetailInfo> = {};
    for (const attribute in netChangesForEntity) {
      if (netChangesForEntity[attribute] && netChangesForEntity[attribute].status !== "accepted") {
        netChangesThatWereNotAccepted[attribute] = netChangesForEntity[attribute];
      }
    }
    return netChangesThatWereNotAccepted;
  } else if (createWasAccepted && deleteWasAccepted) {
    // fix for TB-7064
    // only return deletedAt/deletedBy attributes here to mimic behavior of accepted delete of an entity created pre-signing (because accepting changes created a new baseline)
    const netChangesAcceptedDeleteOnly: Record<string, ChangeDetailInfo> = {};
    assertIsDefined(netChangesForEntity.deletedAt, "netChangesForEntity.deletedAt");
    assertIsDefined(netChangesForEntity.deletedBy, "netChangesForEntity.deletedBy");
    netChangesAcceptedDeleteOnly.deletedAt = netChangesForEntity.deletedAt;
    netChangesAcceptedDeleteOnly.deletedBy = netChangesForEntity.deletedBy;
    if (netChangesForEntity.metadata) {
      netChangesAcceptedDeleteOnly.metadata = netChangesForEntity.metadata;
    }
    return netChangesAcceptedDeleteOnly;
  } else {
    return netChangesForEntity;
  }
};

const getNetChangeForAttribute = (
  changesForAttribute: ChangeDetailInfo[],
): ChangeDetailInfo | null => {
  const noChange = null;

  if (changesForAttribute.length === 0) {
    return noChange;
  }

  const firstChange = changesForAttribute[0];
  assertIsDefined(firstChange, "firstChange");
  const lastChange = changesForAttribute[changesForAttribute.length - 1];
  assertIsDefined(lastChange, "lastChange");

  if (changesForAttribute.length === 1) {
    if (valueDidNotChange(firstChange)) {
      return noChange;
    } else {
      return firstChange;
    }
  } else if (lastChange.status === "accepted") {
    if (valueDidNotChange(lastChange)) {
      return noChange;
    } else {
      return lastChange;
    }
  } else {
    // multiple changes, but last change was not accepted

    const acceptedChanges = changesForAttribute.filter((cdi) => cdi.status === "accepted");
    // baseline change is the change we use to figure out the most recent signed or accepted value for this attribute
    const baselineChange =
      acceptedChanges.length > 0 ? acceptedChanges[acceptedChanges.length - 1] : firstChange;
    assertIsDefined(baselineChange, "baselineChange");

    const lastSignedOrAcceptedValue =
      baselineChange.status === "accepted"
        ? { value: baselineChange.currentValue.value, date: baselineChange.currentValue.date }
        : // change was declined or pending and change was the first change,
          // so change's previous value is the last signed or accepted value
          { value: baselineChange.previousValue.value, date: baselineChange.previousValue.date };

    const netChange: ChangeDetailInfo = {
      status: lastChange.status,
      currentValue: lastChange.currentValue,
      previousValue: lastSignedOrAcceptedValue,
    };

    if (valueDidNotChange(netChange)) {
      // TB-6962 - see "changing after accepting then reverting the changes" test
      if (netChange.status === "pending" && baselineChange.status === "accepted") {
        return baselineChange;
      } else {
        return noChange;
      }
    }

    // TB-6975 - see "declined, then pending changes cancel each other out - net change is the original declined change" test case
    const netChangeIsSameAsBaselineChange =
      netChange.status === "pending" &&
      (baselineChange.status === "accepted" || baselineChange.status === "declined") &&
      netChange.currentValue.value === baselineChange.currentValue.value &&
      netChange.previousValue.value === baselineChange.previousValue.value;
    if (netChangeIsSameAsBaselineChange) {
      return baselineChange;
    }

    return netChange;
  }
};

const getChangeDetailInfo = (changeLogs: ChangeWithDetails[]): Record<string, ChangeDetailInfo> => {
  const entityChangesByAttribute: Record<string, ChangeDetailInfo[]> = {};
  for (const changeLog of changeLogs) {
    for (const changeDetail of changeLog.changeDetails) {
      const change: ChangeDetailInfo = {
        status: changeDetail.status,
        currentValue: {
          value: changeDetail.value,
          user: changeLog.createdByUser,
          date: changeDetail.createdAt,
        },
        previousValue: {
          value: changeDetail.previousValue,
          date: changeDetail.createdAt,
        },
      };
      entityChangesByAttribute[changeDetail.attributeKey] = [
        ...(entityChangesByAttribute[changeDetail.attributeKey] ?? []),
        change,
      ];
    }
  }

  return getNetChangesForEntity(entityChangesByAttribute);
};

const getRelationalChangeDetailInfo = (
  changeLogs: ChangeWithDetails[],
): Record<string, ChangeDetailRecordWithMetadata<string>> => {
  const changeLogById: Record<string, ChangeWithDetails[]> = {};

  for (const changeLog of changeLogs) {
    if (changeLogById[changeLog.entityId]) {
      changeLogById[changeLog.entityId]?.push(changeLog);
    } else {
      changeLogById[changeLog.entityId] = [changeLog];
    }
  }

  const changeDetailRecord: Record<string, ChangeDetailRecordWithMetadata<string>> = {};
  for (const entityId in changeLogById) {
    const change = changeLogById[entityId];
    if (!change) continue;
    const changeDetailInfo = getChangeDetailInfo(change);
    if (Object.values(changeDetailInfo).length === 0) continue;
    changeDetailRecord[entityId] = changeDetailInfo;
    if (change[0]?.metadata) {
      const changeDetail = changeDetailRecord[entityId];
      changeDetail.metadata = change[0].metadata;
    }
  }

  return changeDetailRecord;
};

export const getDEIFChangeSnapshot = (changeLogs: ChangeWithDetails[]): DEIFChangeSnapshot => {
  const changeLists: Record<DEIFSnapshotEntity, ChangeWithDetails[]> = {
    Client: [],
    Policy: [],
    EmployeeClass: [],
    EmployeeClassPlan: [],
    Contact: [],
    Bill: [],
    BillLocation: [],
    ContactLocation: [],
    Location: [],
    Plan: [],
    MonthlyClaimsReportMailingLocation: [],
    Subsidiary: [],
  };

  const changeLogsSorted = changeLogs
    .slice()
    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

  for (const changeLog of changeLogsSorted) {
    if (isDEIFChangeSnapshotEntity(changeLog.entity)) {
      changeLists[changeLog.entity].push(changeLog);
    }
  }

  const changeSnapshot: DEIFChangeSnapshot = {
    Client: getChangeDetailInfo(changeLists["Client"]),
    Policy: getRelationalChangeDetailInfo(changeLists["Policy"]),
    EmployeeClass: getRelationalChangeDetailInfo(changeLists["EmployeeClass"]),
    EmployeeClassPlan: getRelationalChangeDetailInfo(changeLists["EmployeeClassPlan"]),
    Contact: getRelationalChangeDetailInfo(changeLists["Contact"]),
    Location: getRelationalChangeDetailInfo(changeLists["Location"]),
    Bill: getRelationalChangeDetailInfo(changeLists["Bill"]),
    BillLocation: getRelationalChangeDetailInfo(changeLists["BillLocation"]),
    ContactLocation: getRelationalChangeDetailInfo(changeLists["ContactLocation"]),
    Plan: getRelationalChangeDetailInfo(changeLists["Plan"]),
    MonthlyClaimsReportMailingLocation: getRelationalChangeDetailInfo(
      changeLists["MonthlyClaimsReportMailingLocation"],
    ),
    Subsidiary: getRelationalChangeDetailInfo(changeLists["Subsidiary"]),
  };
  return changeSnapshot;
};

export function getAreTherePendingChanges(args: {
  changeSnapshot: DEIFChangeSnapshot;
  client: Client;
}) {
  const { changeSnapshot, client } = args;

  for (const eifSubStepId of eifSubStepIds) {
    const list = getChangeDetailInfoListForSubStep({
      eifSubStepId,
      changeSnapshot,
      client,
      contacts: client.contacts,
      clientPlans: client.plans,
      bills: client.bills,
      deletedBills: client.deletedBills,
      policies: client.policies,
      subsidiaries: client.subsidiaries,
    });
    const anyPendingChanges = list.some(
      (item) => item && "status" in item && item.status === "pending",
    );
    if (anyPendingChanges) return true;
  }

  return false;
}

export function wasEntityAddedInLatestICEdit(entity: { createdAt?: Date }, client: Client) {
  if (entity.createdAt == null) return true;
  const lastSignedDeclinedAccepted = new Date(
    Math.max(
      client.eifSignedAt?.getTime() ?? 0,
      client.deifChangesAcceptedAt?.getTime() ?? 0,
      client.deifChangesDeclinedAt?.getTime() ?? 0,
    ),
  );
  return !!(client.eifSignedAt && entity.createdAt > lastSignedDeclinedAccepted);
}

export function getIsChangeDetailInfo(
  recordWithMetadata: ChangeDetailInfo | ChangeMetadata | undefined,
): recordWithMetadata is ChangeDetailInfo {
  const isMetadata = recordWithMetadata && "parentId" in recordWithMetadata;
  return !isMetadata;
}

export function getWereCoveragesChanged(client: Client, changeSnapshot: DEIFChangeSnapshot | null) {
  if (changeSnapshot == null) return false;

  const coveragesChanged = client.policies.some(
    (policy) => !!changeSnapshot.Policy[policy.id]?.slfCoverages,
  );
  return coveragesChanged;
}

export function getWasMostRecentChangeAnUndo(client: Client): boolean {
  // if we have undone changes since the last time we saved a baseline (accepted changes or signed)
  if (client.deifChangesUndoneAt == null) return false;
  if (client.deifChangesReadyForReviewAt != null) return false;

  const latestBaselineChangeDate = [client.deifChangesAcceptedAt, client.eifSignedAt]
    .filter((date) => date != null)
    .sort((a, b) => b.getTime() - a.getTime())[0];

  if (latestBaselineChangeDate == null) return false;

  return client.deifChangesUndoneAt > latestBaselineChangeDate;
}
