// View Transitions API is not implemented in Firefox yet
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- ^
if (document.startViewTransition === undefined) {
  document.startViewTransition = (cb) => {
    cb?.();

    return {
      finished: Promise.resolve(undefined),
      ready: Promise.resolve(undefined),
      updateCallbackDone: Promise.resolve(undefined),
      skipTransition: () => {
        /* noop */
      },
      types: new Set<string>(),
    };
  };
}
