import type { english } from "client/src/i18n/locales/en";

import type { ValueOf } from "shared/types/Helper";

// union of all supported locales
export type Locale = ValueOf<typeof LocaleKeys>;

export const LocaleKeys = ["en", "es"] as const;

export type BaseLocale = typeof english;

export const defaultLocale: Locale = LocaleKeys[0];
